export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backButton = require('../assets/imagebackicon.png');
export const rightIcone = require('../assets/right.png');
export const scalingIcon = require("../assets/scalingIcon.svg");
export const pdfIcon = require("../assets/pdfIcon.svg");
export const pdfFileIcon = require("../assets/pdfFileIcon.svg");
export const userIcon = require("../assets/userIcon.svg");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const cancelIcon = require("../assets/cancelIcon.svg");
export const pdfFileWarningIcon = require("../assets/pdfFileWarningIcon.svg");
export const warningIcon = require("../assets/warningIcon.svg");

export const backArrowIcon = require("../assets/backArrowIcon.svg");
export const cursorIcon = require("../assets/cursorIcon.svg");
export const handIcon = require("../assets/handIcon.svg");
export const lineIcon = require("../assets/lineIcon.svg");
export const rectangleIcon = require("../assets/rectangleIcon.svg");
export const deleteMiniIcon = require("../assets/deleteMiniIcon.svg");
export const lineMiniIcon = require("../assets/lineMiniIcon.svg");
export const rectangleMiniIcon = require("../assets/rectangleMiniIcon.svg");
export const cursorMiniIcon = require("../assets/cursorMiniIcon.svg");
export const handMiniIcon = require("../assets/handMiniIcon.svg");

export const toolone = require('../assets/image.png')
export const tooltwo = require('../assets/image (1).png')
export const toolthree = require('../assets/image (2).png')
export const toolfour = require('../assets/image (3).png')
export const toolfive = require('../assets/image (4).png')
export const toolsix = require('../assets/image (5).png')
export const toolseven = require('../assets/image (6).png')
export const electricicon = require('../assets/image (7).png')
export const highlightone = require('../assets/Image Pasted at 2025-1-21 18-03.png')
export const highlighttwo = require('../assets/Image Pasted at 2025-1-21 18-04.png')
export const highlightthree = require('../assets/Image Pasted at 2025-1-21 18-04(1).png')
export const highlightfour = require('../assets/Image Pasted at 2025-1-21 18-04(2).png')
export const reload = require('../assets/Image Pasted at 2025-2-6 18-27.png')
export const noData = require('../assets/Image Pasted at 2025-4-3 16-21.png')