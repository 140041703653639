import React from "react";
import VisualAnalyticsController, {
    Props,
    configJSON,
  } from "./VisualAnalyticsController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import "./ViewDetail.css";
import { Box,
  IconButton,
  InputAdornment,
  TextField} from "@mui/material";
import { Collapse, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { expandUpArrow,directoryIcon,downArrow, expandDownArrow } from "./assets";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      body1: {
        fontFamily: "Poppins",
      },
    },
  });


export default class ViewDetailPage extends VisualAnalyticsController {
    constructor(props: Props) {
      super(props);
    }

    rowDatareturn=(dataValue:any,i:any)=>{
        const expandedOne = dataValue.id === this.state.levelOneId ;

        return(
          <>
        <TableRow
         style={{fontSize:"16px",fontWeight:400,color:"#0F172A", fontFamily : "Poppins"}}
        >
        <TableCell align="left"
        style={{fontSize:"16px",fontWeight:400,color:"#0F172A",fontFamily : "Poppins", display : "flex"}}
        > 
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => this.handleOpenSubTaskList(dataValue?.id)}
            style={{marginRight:"15px", height : "24px", width : "24px"}}
          >
            {(this.state.openTaskList && expandedOne) ? <img src={expandUpArrow} alt="down"/>:<img src={downArrow} alt="down"/>}
         </IconButton>   
         <Typography style={{fontSize:"16px",fontFamily : "Poppins",fontWeight:400,color:"#0F172A"}}>{dataValue.attributes.room_name}</Typography>
        </TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontFamily : "Poppins",fontWeight:400,color:"#0F172A"}}>{this.formatDate(dataValue.attributes && dataValue.attributes.start_date) }</TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontWeight:400,fontFamily : "Poppins",color:"#0F172A"}}>{this.formatDate(dataValue.attributes && dataValue.attributes.start_date) }</TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontFamily : "Poppins",fontWeight:400,color:"#0F172A"}}>{dataValue.attributes && dataValue.attributes.retention }</TableCell>
        <TableCell align="left" style={{fontSize:"16px",fontFamily : "Poppins",fontWeight:400,color:"#0F172A"}}>
          {dataValue.attributes && dataValue.attributes.room_cost && parseFloat(dataValue.attributes.room_cost).toFixed(2)}
          </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={(this.state.openTaskList && expandedOne)} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">  
                <TableBody>
                  {
                  (this.state.subTaskListData && this.state.subTaskListData.data && this.state.subTaskListData.data.length)?
                  this.state.subTaskListData.data.map((val:any,i:any) => {
                    return this.rowDatareturnTwo(val)
                  }):(
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                        No data available.
                        </TableCell>
                    </TableRow>
                    )
                }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
          </>
        )
    }

    rowDatareturnTwo =(dataValue:any)=>{
        const expandedTwo = dataValue.id === this.state.levelTwoId 
        const attributes = dataValue && dataValue.attributes
        const attributesValue =   attributes?.task_list_name

        return(
          <>
           <TableRow data-test-id="sublistClickBtn">
             <TableCell style={{display: "flex", alignItems : "center"}}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  data-test-id="iconBtnClick"
                  style={{ marginRight: "15px" }}
                >
                  <SubdirectoryArrowRightIcon sx={{ color: "#94A3B8" }} />
                </IconButton>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  style={{ marginRight: "15px", height: "24px", width: "24px" }}
                >
                  <KeyboardArrowDownIcon sx={{ color: "#0F172A" }} onClick={this.handleSUbListOpenTask.bind(this,dataValue && dataValue.id)} />
                </IconButton> 
             <Typography style={{fontSize:"16px",fontWeight:400,color:"#0F172A", fontFamily : "Poppins"}}>{attributes && attributes.task_list_name}</Typography>
            </TableCell> 
           </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={(expandedTwo && this.state.subList)} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <Table size="small" aria-label="purchases" style={{ borderSpacing: "0 4px", borderCollapse: "separate" }}>  
                        <TableHead style={{backgroundColor:"#D3E4E9",}}>
                            <TableRow>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Task</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Specifications</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Qty</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Measurement</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Labour cost / unit</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Materials cost / unit</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Unit cost</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Total Cost</TableCell>
                                <TableCell align="left" style={{fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Client Supplied</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{width:"100%"}}>
                        {attributes && attributes.project_tasks.map((val:any,i:any) => (
                            <TableRow key={i} style={{backgroundColor:"#F0F5F7",marginTop:"10px", marginBottom : '8px',borderRadius: "4px"}}>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins"}}>
                                {attributesValue}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins"}}>
                            {val.attributes && val.attributes.specifications[0] && val.attributes.specifications[0].specification_name}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins" , textAlign : "center"}}>
                            {val.attributes && val.attributes.specifications[0] && val.attributes.specifications[0].quantity}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins" , textAlign : "center"}}>
                            {val.attributes && val.attributes.specifications[0] && val.attributes.specifications[0].measurement_unit}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins" , textAlign : "center"}}>
                            £{val.attributes && val.attributes.specifications[0] && val.attributes.specifications[0].labour_cost}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins" , textAlign : "center"}}>
                            £{val.attributes && val.attributes.specifications[0] && val.attributes.specifications[0].material_cost}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins" , textAlign : "center"}}>
                            £{val.attributes && val.attributes.specifications[0] && val.attributes.specifications[0].total_cost}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins" , textAlign : "center"}}>
                            £{val.attributes && val.attributes.specifications[0] && val.attributes.specifications[0].unit_total}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{fontSize:"14px",fontWeight:400, fontFamily : "Poppins" , textAlign : "center"}}>
                              <StyledCheckbox
                                type="checkbox"
                                // checked={isChecked}
                                date-test-id="clientFilterTest"
                              />
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </Box>
                </Collapse>
                </TableCell>
            </TableRow>
          </>
        )
    }
     CustomSelectIcon = (props: { open: any; }) => {
      return props.open ? <KeyboardArrowUpIcon sx={{ml : "-20px"}}/> : <KeyboardArrowDownIcon sx={{ml : "-20px"}}/>;
    };

    render() {
  
      return (
        <ThemeProvider theme={theme}>
            <MyProfileHeader showSidebar={true} navigation={this.props.navigation} >
              <Box sx={styles.parentCont}>
                 <div className="container">
                     <div style={{display:"flex",gap:"30px",alignItems:"center"}}>
                        <span style={{color:"#334155",fontSize:"16px",fontWeight:600,display:"flex",alignItems:"center",fontFamily : "Poppins"}} onClick={this.navigateSideTabs.bind(this,"VisualAnalytics")}><ChevronLeftIcon style={{ color: "#334155" }} />Back</span>
                        <span  style={{color:"#0F172A",fontWeight:700,fontSize:"24px", fontFamily : "Poppins"}}> {configJSON.textHeader}</span>
                     </div>
                     <div className="subHeader">
                       <Box>
                        <TextField
                            variant="outlined"
                            placeholder="Search here..."
                            data-test-id="search-input"
                            value={this.state.searchText}
                            onChange={this.handleSearch}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <SearchIcon
                                    style={{
                                    height: "20px",
                                    color: "#94A3B8",
                                    width: "20px",
                                    }}
                                />
                                </InputAdornment>
                            ),
                            style: {
                                width: "400px",
                                height: "40px",
                                borderRadius: "8px",
                                fontFamily:"poppins"
                            },
                            }}
                            inputProps={{
                            style: {
                            padding: "5px 5px", 
                            fontFamily:"Poppins"
                            },
                            maxLength: 50,
                            }}
                            style={{
                            borderRadius: "8px",
                            backgroundColor: "white",
                            height: "40px",
                            marginRight: "0.9rem",
                            fontFamily:"poppins"
                            }}
                            sx={{
                            "& .MuiInputBase-input::placeholder": {
                                fontFamily: "Poppins",
                            },
                            }}
                         />
                      </Box>
                  <Box style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                    <Typography style={{ fontSize: "14px", color: "#334155", fontWeight: 400, fontFamily: "Poppins" }}>Sort by:</Typography>
                    <Select
                      value={this.state.optionValue}
                      onChange={this.handleChangeDropDown}
                      style={{
                        fontSize: "16px",
                        color: "#237182",
                        fontWeight: 600,
                        fontFamily: "Poppins",
                        cursor : "pointer"
                      }}
                      variant="standard"
                      disableUnderline
                      data-test-id="sortValue"
                      IconComponent={(props) => <this.CustomSelectIcon open={this.state.isMenuOpen} />} 
                      onOpen={() => this.setState({ isMenuOpen: true })}
                      onClose={() => this.setState({ isMenuOpen: false })}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem style={{
                        fontSize: "16px", color: "#334155",
                        fontWeight: 600,
                        fontFamily: "Poppins"
                      }} value="NONE">
                        None
                      </MenuItem>
                      <MenuItem style={{
                        fontSize: "16px", color: "#334155",
                        fontWeight: 600,
                        fontFamily: "Poppins"
                      }} value="NAME">
                        Name
                      </MenuItem>
                      <MenuItem style={{
                        fontSize: "16px", color: "#334155",
                        fontWeight: 600,
                        fontFamily: "Poppins"
                      }} value="START_DATE">
                        Start Date
                      </MenuItem>
                    </Select>
                  </Box>
                     </div>
                     <div className="tableBody">
                     <Table style={{ width: '100%' }}>
                     <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width: "25%" , paddingLeft : "40px", fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Rooms / Area Type</TableCell>
                            <TableCell align="left" style={{width: "20%" , fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Start Date</TableCell>
                            <TableCell align="left" style={{width: "20%" , fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>End Date</TableCell>
                            <TableCell align="left" style={{width: "20%" , fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Retention %</TableCell>
                            <TableCell align="left" style={{width: "15%" , fontSize:"14px",fontWeight:600, fontFamily : "Poppins"}}>Cost</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {this.state.pieChartData?.data?.data?.length > 0 ? (
                        this.state.pieChartData.data.data
                        .filter((item: any) => 
                          this.state.searchText === '' || 
                          item?.attributes?.room_name.toLowerCase().includes(this.state.searchText.toLowerCase())
                        ).map((val: any,i:any) => {
                            return this.rowDatareturn(val,i);
                        })
                        ) : (
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                            No data available.
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                     </Table>
                     </div>
                 </div>
              </Box>
            </MyProfileHeader>
        </ThemeProvider>
      );
    }
  }

  const CustomDropDown = styled(TextField)({
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgb(240, 245, 247)",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
      color: "#237182",
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiSelect-select": {
      paddingBlock: "10px",
      color: "#237182",
      fontSize: "16px",
      fontWeight: 600,
      border:"1px solid rgb(240, 245, 247)",
      outline:"1px solid rgb(240, 245, 247)"
    },
    "& .MuiSvgIcon-root": {
      color: "#237182",
    },
  });

const styles = {
    parentCont: {marginLeft: {sm:'5.5rem', xs: '6rem'},padding: '30px 50px 10px 50px', gap: 3, display: 'flex', flexDirection: 'column',backgroundColor: "#F0F5F7"},
  };

  const StyledCheckbox = styled('input')({
    width: "20px",
    height: "20px",
    borderRadius: "6px",
    border: "1px solid #64748B",
    cursor: "pointer",
    position: "relative",
    appearance: "none",
    outline: "none",
    backgroundColor: "#F0F5F7",
    marginRight: "10px",
    marginLeft: "0px",
    "&:checked": {
     backgroundColor: "#325962",
     border: "none"
   },
   "&:checked::after": {
     content: '""',
     position: "absolute",
     top: "2px",
     left: "7px",
     width: "5px",
     height: "10px",
     border: "solid white",
     borderWidth: "0 2px 2px 0",
     transform: "rotate(45deg)"
   }
 });