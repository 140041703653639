import React from "react";
import VisualAnalyticsController, {
    Props,
    configJSON,
  } from "./VisualAnalyticsController";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import "./ViewDetail.css";
import { Box} from "@mui/material";
import {  Tab, Tabs, Typography } from "@material-ui/core";
import moment from "moment";
import { ArrowBackIos, ArrowForwardIos, CheckCircleRounded, DateRange, OpenInNewOutlined } from "@material-ui/icons";
import "./Style.css";
import { checkListIcon } from "../../../blocks/ganttchart/src/assets";

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      body1: {
        fontFamily: "Poppins",
      },
    },
  });


export default class GanttVisual extends VisualAnalyticsController {
    constructor(props: Props) {
      super(props);
    }
    customTab=()=>{
      return(
        <CustomTab 
                 value={this.state.activeTab} 
                 onChange={this.handleChange} 
                 style={{display:"flex",gap:"5px",color:"#325962",backgroundColor:"#F8FAFC",flexDirection: "row",borderRadius:"50px",zIndex: 0,position: "relative",padding:0}}>
                    <Tab label="Week"  style={{minWidth:"auto",...webStyle.tabTitle,...(this.state.activeTab === 0 && {
                      background: "#E0EDF0", 
                      borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                    })
                    }} value={0} />

                    <Tab label="Month" style={{minWidth:"auto",...webStyle.tabTitle,...(this.state.activeTab === 1 && {
                      background: "#E0EDF0", 
                      borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                    })}} value={1}/>
                    <Tab label="Quater" style={{minWidth:"auto",...webStyle.tabTitle,
                      ...(this.state.activeTab === 2 && {
                        background: "#E0EDF0", 
                        borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                      })
                    }} value={2}/>
                    <Tab label="Year" style={{minWidth:"auto",...webStyle.tabTitle,
                      ...(this.state.activeTab === 3 && {
                        background: "#E0EDF0", 
                        borderRadius: "50px",color:'#325962',border:'1px solid #D3E4E9'
                      })
                    }} value={3}/>
                 </CustomTab>
      )
    }
    RenderQuaterCalendar() {
        const quarters = ["Q1", "Q2", "Q3", "Q4"];
    
        const currentMonth = this.state.initialDate.getMonth();
        const currentYear = this.state.initialDate.getFullYear();
        const quarterIndex = Math.floor(currentMonth / 3);
        const currentQuarter = quarters[quarterIndex];
    
        const startOfQuarter = moment([currentYear, quarterIndex * 3, 1]); 
        const endOfQuarter = moment(startOfQuarter).add(2, 'months').endOf('month');
    
        return (
          <div className="container">
             <div className="header2">
              <div className="monthTitle">
              <div style={{display:'flex',gap:'16px'}}>
                 {this.customTab()}
                  <div className="monthName">
                    <span onClick={this.handlePreviousWeek}><ArrowBackIos style={{width:'18px',height:'18px'}}/></span>
                    <span style={{fontWeight:600,fontFamily:"Poppins",color:"#325962",fontSize:"16px",marginLeft:'8px',marginRight:'8px'}}>
                      {currentQuarter} {currentYear}
                    </span>
                    <span onClick={this.handleNextWeek}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
                  </div>
                </div>
                  <div className="monthDuration" onClick={this.navigateGantt} style={{display:"flex",cursor:"pointer"}}>
                    <div style={webStyle.periodText}>Go to Gantt Chart</div> 
                    <OpenInNewOutlined style={{color:'#237182',height:'16px'}}/>
                    </div>
              </div>
              <div>
                {this.renderQuarterWeeks(startOfQuarter, endOfQuarter)}
              </div>
             </div>
          </div>
        )
    }
    
    renderQuarterWeeks(startOfQuarter: moment.Moment, endOfQuarter: moment.Moment) {
      let weeks = [];
      let currentWeekStart = moment(startOfQuarter).startOf('week');
      let weekNumber = 1;
    
      while (currentWeekStart.isBefore(endOfQuarter) || currentWeekStart.isSame(endOfQuarter, 'week')) {
          weeks.push(`Week ${weekNumber}`);
          currentWeekStart.add(1, 'week');
          weekNumber++;
      }
    
      return (
        <div style={{ display: "flex", justifyContent: 'space-around', marginTop: '10px' }}>
          {weeks.map((week, id) => (
            <div key={id} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <span style={webStyle.monthText}>Week</span>
              <span style={webStyle.monthText}>{week.substring(4)}</span>
            </div>
          ))}
        </div>
      );
    }
    
    
      RenderYearCalendar(){
        return (
          <div className="container">
             <div className="header2">
              <div className="monthTitle">
              <div style={{display:'flex',gap:'16px'}}>
              {this.customTab()}
                  <div className="monthName">
                    <span onClick={this.handlePreviousWeek}><ArrowBackIos style={{width:'18px',height:'18px'}}/></span>
                    <span style={{fontWeight:600,fontFamily:"Poppins",color:"#325962",fontSize:"16px",marginLeft:'8px',marginRight:'8px'}}>{this.state.initialDate.getFullYear()}</span>
                    <span onClick={this.handleNextWeek}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
                  </div>
                </div>
                  <div className="monthDuration" onClick={this.navigateGantt} style={{display:"flex",cursor:"pointer"}}>
                    <div style={webStyle.periodText}>Go to Gantt Chart</div> 
                    <OpenInNewOutlined style={{color:'#237182',height:'16px'}}/>
                     </div>
              </div>
              <div className="month">
               {this.state.monthName?.map((val:any)=>{
                  return(
                    <div className="monthDiv" key={val?.id}>
                      <span style={webStyle.monthText}>{val?.name}</span>
                      <span style={webStyle.dateText}>{val?.id}</span>
                    </div>
                  )})}
              </div> 
             </div>
          </div>
        )
      }
      getDaysInMonth(year:any, month:any) {
        return new Date(year, month + 1, 0).getDate();
      }
      generateMonthData(date: Date) {
        if (!date) return; 
      
        const daysInMonth = moment(date).daysInMonth(); 
        const monthData = [];
      
        for (let i = 1; i <= daysInMonth; i++) {
          const dayName = moment(date).date(i).format("dd").charAt(0); 
          monthData.push({ id: i, name: dayName });
        }
      
        this.setState({ monthName: monthData });
      }
      
      weekCalendar=()=>{
        const currentWeek = moment(this.state.initialDate).week();

  const startOfWeek = moment(this.state.initialDate).startOf("week");
  const weekDays = [];
  for (let i = 0; i < 7; i++) {
    const day = startOfWeek.clone().add(i, "days");
    weekDays.push({
      date: day.format("DD"), 
      dayName: day.format("ddd"), 
    });
  }

  return (
    <div style={{background:'#fff',padding:'7px 7px 0px 7px', borderTopRightRadius: '20px', border: '1px solid #E2E8F0'}}>
        <div className="rbc-btn-group">
          <div style={{display:'flex'}}>
        {this.customTab()}
        <div style={{display:'flex',alignItems:'center'}}>
          <span onClick={this.handlePreviousWeek} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowBackIos style={{width:'18px',height:'18px'}}/> </span>
          <span style={{fontWeight:600,fontFamily:"Poppins",color:"#325962",fontSize:"16px",marginLeft:'8px',marginRight:'8px'}}>Week {" "}{" "}{currentWeek}</span> 
          <span onClick={this.handleNextWeek} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
        </div>
        </div>
        <div className="monthDuration" onClick={this.navigateGantt} style={{display:"flex",cursor:"pointer"}}>
                    <div style={webStyle.periodText}>Go to Gantt Chart</div> 
                    <OpenInNewOutlined style={{color:'#237182',height:'16px'}}/>
                    </div> 
      </div>
      <div style={{ display: "flex", justifyContent:"space-between",marginTop:"25px" }}>
          {weekDays.map((day, index) => (
            <div key={index} style={{ textAlign: "center" }}>
              <div style={webStyle.monthText}>{day.dayName}</div>
              <div style={webStyle.dateText}>{day.date}</div>
            </div>
          ))}
        </div>
    </div>
  );
      }
      monthCalendar=()=>{
        const currentMonth = moment(this.state.initialDate).format('MMMM');
        const currentYear = moment(this.state.initialDate).year();
       
        const startOfMonth = moment(this.state.initialDate).startOf("month");
        const endOfMonth = moment(this.state.initialDate).endOf("month");
        const daysInMonth = [];
        let date = startOfMonth.clone();
        
        while (date.isBefore(endOfMonth) || date.isSame(endOfMonth, "day")) {
            daysInMonth.push({
                date: date.format("DD"),   
                day: date.format("dd").charAt(0),
                fullDate: date.format("YYYY-MM-DD"),
            });
            date.add(1, "day"); 
        }
       
      return(

          <div style={{background:'#fff',paddingTop:'8px',paddingLeft:'8px',paddingRight:"8px",paddingBottom:'13px',borderTopRightRadius: '20px', border: '1px solid #E2E8F0'}}>
           <div className="rbc-btn-group" >
            <div style={{display:'flex'}}>
            {this.customTab()}
           <div style={{display:'flex',alignItems:'center'}}>
             <span onClick={this.handlePreviousWeek} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowBackIos style={{width:'18px',height:'18px'}}/></span>
             <span style={{fontWeight:600,fontSize:"16px",fontFamily:"Poppins",color:"#325962",marginLeft:'8px',marginRight:'8px'}}>{currentMonth} {" "}{" "}{" "}{currentYear}</span> 
             <span onClick={this.handleNextWeek} style={{ cursor: 'pointer',color:"#325962",fontWeight:600,fontSize:"20px"}}><ArrowForwardIos style={{width:'18px',height:'18px'}}/></span>
           </div>
           </div>
           <div className="monthDuration" onClick={this.navigateGantt} style={{display:"flex",cursor:"pointer"}}>
                    <div style={webStyle.periodText}>Go to Gantt Chart</div> 
                    <OpenInNewOutlined style={{color:'#237182',height:'16px'}}/>
                    </div> 
         </div>
         <div style={{display:'flex',justifyContent:'space-between'}}>
         {daysInMonth.map((day) => (
                    <div key={day.fullDate} style={{ padding: '5px', background: "#F8FAFC", borderRadius: "5px" }}>
                        <div style={webStyle.monthText}>{day.day}</div>
                        <div style={webStyle.dateText}>{day.date}</div>
                    </div>
                ))}
     </div>
         </div>
        
      )
      }

      getRoomWidth(startDate: any, endDate: any): number {
        const { activeTab, initialDate } = this.state;
        let dayMultiplier = 150, dateMultiplier = 33, weekMultiplier = 90, monthMultiplier = 105;
      
        const start = new Date(startDate).getTime();
        const end = new Date(endDate).getTime();
        
        if (isNaN(start) || isNaN(end) || start > end) return 0;
      
        let visibleStart: number, visibleEnd: number;
      
        if (activeTab === 0) { // Week View
            const startOfWeek = new Date(initialDate);
            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
      
            visibleStart = startOfWeek.getTime();
            visibleEnd = endOfWeek.getTime();
            
            return ((Math.min(end, visibleEnd) - Math.max(start, visibleStart)) / (1000 * 60 * 60 * 24) + 1) * dayMultiplier;
        } 
        
        else if (activeTab === 1) { // Month View
            visibleStart = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1).getTime();
            visibleEnd = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0).getTime();
      
            return ((Math.min(end, visibleEnd) - Math.max(start, visibleStart)) / (1000 * 60 * 60 * 24) + 1) * dateMultiplier;
        } 
        
        else if (activeTab === 2) { // Quarter View
            const quarterStartMonth = Math.floor(initialDate.getMonth() / 3) * 3;
            visibleStart = new Date(initialDate.getFullYear(), quarterStartMonth, 1).getTime();
            visibleEnd = new Date(initialDate.getFullYear(), quarterStartMonth + 3, 0).getTime();
      
            return Math.ceil((Math.min(end, visibleEnd) - Math.max(start, visibleStart)) / (7 * 24 * 60 * 60 * 1000)) * weekMultiplier;
        } 
        
        else if (activeTab === 3) { // Year View
            visibleStart = new Date(initialDate.getFullYear(), 0, 1).getTime();
            visibleEnd = new Date(initialDate.getFullYear(), 11, 31).getTime();
      
            const startObj = new Date(Math.max(start, visibleStart));
            const endObj = new Date(Math.min(end, visibleEnd));
            
            return ((endObj.getFullYear() - startObj.getFullYear()) * 12 + (endObj.getMonth() - startObj.getMonth()) + 1) * monthMultiplier;
        }
      
        return 0;
      }
      getRoomPosition(startDate: any) {
        const { activeTab, initialDate } = this.state;
        let dayMultiplier = 150, dateMultiplier = 33, weekMultiplier = 90, monthMultiplier = 105;
      
        const start = new Date(startDate).getTime();
        
        let visibleStart: number;
      
        if (activeTab === 0) {
            const startOfWeek = new Date(initialDate);
            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
            visibleStart = startOfWeek.getTime();
            
            if (start < visibleStart) return 0;
            return ((new Date(startDate).getDay()) * dayMultiplier);
        } 
        
        else if (activeTab === 1) { 
            visibleStart = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1).getTime();
            
            if (start < visibleStart) return 0; 
            return ((new Date(startDate).getDate() - 1) * dateMultiplier);
        } 
        
        else if (activeTab === 2) { 
            const quarterStartMonth = Math.floor(initialDate.getMonth() / 3) * 3;
            visibleStart = new Date(initialDate.getFullYear(), quarterStartMonth, 1).getTime();
            
            const weekNumber = Math.ceil((start - visibleStart) / (7 * 24 * 60 * 60 * 1000));
            if (start < visibleStart) return 0;
            return (weekNumber - 1) * weekMultiplier;
        } 
        
        else if (activeTab === 3) {
            visibleStart = new Date(initialDate.getFullYear(), 0, 1).getTime();
            
            if (start < visibleStart) return 0; 
            return (new Date(startDate).getMonth() * monthMultiplier);
        }
      
        return 0; 
      }
      getWeekStartDate(date: Date) {
        const day = date.getDay(); 
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day);
      }
      
      getWeekEndDate(date: Date) {
        const day = date.getDay();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - day)); 
      }
      isEventInSelectedWeek (event: any, initialDate: Date) {
        const startDate = new Date(event.attributes.start_date);
        const endDate = new Date(event.attributes.end_date);
      
        const weekStart = this.getWeekStartDate(initialDate);
        const weekEnd = this.getWeekEndDate(initialDate);
      
        return (
          (startDate >= weekStart && startDate <= weekEnd) || 
          (endDate >= weekStart && endDate <= weekEnd) ||     
          (startDate < weekStart && endDate > weekEnd)
        );
      };
      
      
        RenderRoomList() {
        const { activeTab, initialDate, events } = this.state;
      
      const selectedYear = initialDate.getFullYear();
      const selectedMonth = initialDate.getMonth();
      const selectedWeek = this.getWeekNumber(initialDate);
      
      const monthStart = new Date(selectedYear, selectedMonth, 1); 
      const monthEnd = new Date(selectedYear, selectedMonth + 1, 0); 
      
      let visibleRooms = events.filter((event: any) => {
        const startDate = new Date(event.attributes.start_date);
        const endDate = new Date(event.attributes.end_date);
      
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const eventWeek = this.getWeekNumber(startDate);
      
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();
        const endWeek = this.getWeekNumber(endDate);
      
        if (activeTab === 3) { 
          return (startYear === selectedYear || endYear === selectedYear);
        } 
      
        else if (activeTab === 1) { 
          return (
            (startDate >= monthStart && startDate <= monthEnd) || 
            (endDate >= monthStart && endDate <= monthEnd) ||
            (startDate < monthStart && endDate > monthEnd) 
          );
        } 
      
        else if (activeTab === 2) { 
          const quarterStartMonth = Math.floor(selectedMonth / 3) * 3;
          const quarterEndMonth = quarterStartMonth + 2;
      
          return (
            (startYear === selectedYear && startMonth >= quarterStartMonth && startMonth <= quarterEndMonth) || 
            (endYear === selectedYear && endMonth >= quarterStartMonth && endMonth <= quarterEndMonth) ||
            (startMonth < quarterStartMonth && endMonth > quarterEndMonth) 
          );
        } 
      
        else if (activeTab === 0) { 
          return this.isEventInSelectedWeek(event, initialDate)
        }
        
        return false;
      });
        
          return (
            <div className="roomListViewCard2">
              {visibleRooms.length==0&&
              <Typography style={{fontFamily:'Poppins',display:'flex',justifyContent:'center',marginTop:'30vh', position:"absolute",left:'41%'}}>No room available</Typography>}
              {visibleRooms.map((val: any) => {
                const startDate = new Date(val.attributes.start_date);
                const endDate = new Date(val.attributes.end_date);          
                return (
                  <div
                    key={val.id}
                    data-test-id="isRoomOpen"
                    style={{
                      marginTop: "1px",
                      display: "grid",
                      width: "100%",
                      gap: "10px",
                      gridTemplateColumns: "200px auto",
                      alignItems: "center",
                      position: "relative",
                    }}
                    
                  >
                    <div className="RoomNameColor2">{val.attributes.room_name}</div>
                    <div className="roomListCard" style={{marginLeft:(this.getRoomPosition(startDate))<0?0:(this.getRoomPosition(startDate)),width:this.getRoomWidth(startDate,endDate)}}
                     data-test-id="isRoomOpen">
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize:'14px',
                            fontFamily: "Poppins",
                            color: "#0F172A",
                            maxWidth: "150px", 
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer"
                          }}
                          data-test-id="namee2"
                          title={val.attributes.room_name}
                          
                        >
                          {val.attributes.room_name}
                        </span>
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                          <div
                            style={{
                              width: "250px",
                              height: "4px",
                              borderRadius: "10px",
                              backgroundColor: "#E2E8F0",
                            }}
                          >
                            <div
                              style={{
                                width: `${
                                  val?.attributes.total_task_list_count === 0 
                                    ? 0 
                                    : (val?.attributes.completed_tasks_count / val?.attributes.total_task_list_count) * 100
                                }%`,
                                
                                backgroundColor: val?.attributes.completed_tasks_count === val.attributes.total_task_list_count ? "#34D399" : "#237182",
                                height: "4px",
                                borderRadius: "10px",
                              }}
                            ></div>
                          </div>
                          <p style={webStyle.percentageText}>
                          {val?.attributes.total_task_list_count === 0 
                            ? "0%" 
                            : ((val?.attributes.completed_tasks_count / val?.attributes.total_task_list_count) * 100) === 0
                              ? "0%"
                              : `${((val?.attributes.completed_tasks_count / val?.attributes.total_task_list_count) * 100).toFixed(2)}%`}
                        </p>
      
      
                        </div>
                      </div>
                      <div className="roomBottomPart">
                        <div>
                          <span
                            style={{
                              fontWeight: 400,
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              color: "#334155",
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <DateRange style={{ height: "18px" }} />
                            {`${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()} - ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              color: "#237182",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: 600,
                              gap: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {val?.attributes.completed_tasks_count !== val?.attributes.total_task_list_count || val?.attributes.total_task_list_count ==0 ?
                              <img src={checkListIcon} alt="checklistIcon" style={{ color: "#237182" }} />
                              :
                              <CheckCircleRounded style={{ color: "#237182", height: "16px" }} />
                             } 
                           {val?.attributes.completed_tasks_count}/{val?.attributes.total_task_list_count} 
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
        
        getWeekNumber(date: Date) {
          const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
          const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
          return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        }
    render() {
  
      return (
        <Box>

                <div style={{marginTop:"10px",display:"grid",width:"1300px",gap:"1px",gridTemplateColumns:"200px auto",height:"115px"}}>
            <div className="roomBlockName2" style={{height:'115px'}}>
               <span style={webStyle.tableHead}>Room Name</span>
            </div>
            
           {
                this.state.activeTab === 0 && 
                <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                {this.weekCalendar()}
                </div> 
            }
           {
                this.state.activeTab === 1 && 
                <div style={{display:"flex",flexDirection:"column",width:"100%"}}> 
                {this.monthCalendar()}
               </div> 
            }
               {
                this.state.activeTab === 2 &&
                this.RenderQuaterCalendar()
               }
               {
                this.state.activeTab === 3 &&
                this.RenderYearCalendar()
               }
                <div 
          style={{ 
            position: "absolute",
            left: this.getVerticalLinePosition(),
            transform: "translateX(-50%)", 
            width: "12px",
            top:748,
            height: "12px",
            backgroundColor: "#237182",
            borderRadius: "50%",
            zIndex: 50,
          }} 
        />
         <div 
          style={{ 
            position: "absolute",
            height: '100vh',
            width: "1px", 
            top:748,
            backgroundColor: "#237182", 
            left: this.getVerticalLinePosition(), 
            transform: "translateX(-50%)",
            transition: "left 0.3s ease-in-out",
            zIndex:50
          }} 
        />
                <div style={{ display: "grid", width: "100%" }}>
              {this.RenderRoomList()}
            </div>
            </div> 
        </Box>
      );
    }
  }

  const CustomTab = styled(Tabs)({
    '& .MuiTabs-root':{
        minHeight:'36px'
    },
    '& .PrivateTabIndicator-colorSecondary-7':{
      display:'none'
    },
    '& .PrivateTabIndicator-colorSecondary-8':{
      display:'none'
    },
    '& .PrivateTabIndicator-colorSecondary-196':{
      display:'none'
    },
    '& .jss8':{
      display:"none"
    },
  })

  const webStyle = {
    tabTitle:{
      color:'#64748B',
      fontSize:"14px",
      fontWeight:600,
      fontFamily:'Poppins',
      textTransform:'none' as 'none',
      border:'none'
    },
    mainTitle:{
      color:'#0F172A',
      fontSize:'24px',
      fontWeight:700,
      fontFamily:"Poppins"
    },
    todayButton:{
      color:"#325962",
      fontSize:'16px',
      fontWeight:600,
      fontFamily:'Poppins',
      background:'#E0EDF0',
      textTransform:'none' as 'none',
      borderRadius:'8px',
      height:'32px',
      marginTop:'12px',
      cursor:'pointer'
    },
    tableHead:{
      color:'#334155',
      fontSize:'14px',
      fontWeight:600,
      fontFamily:"Poppins",
    },
    mainBox: {
      backgroundColor:"#E0EDF0",
      display:"flex",
      flexDirection:"column",
      paddingLeft:"30px",
      paddingRight:"30px"
    },
    calendarRoom:{
     backgroundColor:"#FFFFFF",
     width:"150px",
     height:"auto",
     display:"flex",
     alignItem:"space-between",
     flexDirection:"column",
     padding:"20px",
     marginBottom:"20px"
    },
    searchType:{
     width:"90vw",
     backgroundColor:"#FFFFFF",
     height:"fit-content"
    },
    bg:{
      backgroundColor:"#E2E8F0",
    },
    roomName:{
      height:"200px",
      display:"flex",
      alignItems:"center",
      color:"#475569"
    },
    viewSwitcherWrapper: {
      marginTop: "10px"
    },
    ganttBoxWrapper: {
      marginTop: "20px"
    },
    buttonStyle: {
      marginLeft: "10px",
      border: "none",
      backgroundColor: "#F0F0F0",
    },
    chkboxWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    dateText:{
      color:"#1E293B",
      fontSize:'12px',
      fontWeight:600,
      fontFamily:'Poppins',
    },
    periodText:{
      color:"#237182",
      fontSize:"12px",
      fontWeight:600,
      fontFamily:'Poppins',
    },
    monthText:{
      color:'#334155',
      fontSize:"12px",
      fontWeight:400,
      fontFamily:'Poppins'
    },
    percentageText:{
      color:'#0F172A',
      fontSize:'12px',
      fontFamily:"Poppins",
      fontWeight:400
    },
      iconDetailColorStyle:{
        color:"#475569", width:"24px", height:"24px", radius:"40px",
      },
      multipleDetailDataStyle:{
        fontFamily:"Poppins",
        fontSize:"14px",
        fontWeight:400,
        color:"#475569",
      },
      roomDataStyle:{
        display:"flex", justifyContent:"start",
        alignItems:"center", gap:"8px", fontFamily:"poppins",
        fontSize:'12px',
        fontWeight:600,
        color:"#64748B"
      },
      filterSection:{
        padding:"6px 8px 6px 0px",
        display:"flex",
        fontWeight:600,
        fontFamily:"poppins",
        justifyContent:"space-between",
        alignItems:"center",
        fontSize:"14px",
        gap:"10px",
        color:"#0F172A"
      },
      renameText: {
        fontSize: "1.5rem",
        fontWeight: 700, lineHeight: "2rem",
      },
      modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        color: "#0F172A",
        alignItems: "center",
        marginBottom: "1rem",
      },
      renameModal: {
        margin: "auto", width: "40vw",
        background: "white",
        height: "fit-content", padding: "2.5rem 2rem",
        boxShadow:'none',
        borderRadius: "16px",
        outline:'none'
      },
      filterModal: {
        height: "100%",
        overflowY:"scroll",
        padding: "2.5rem 2rem",
        width: "40vw",
        position: "fixed",
        boxShadow:
          "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
        background: "white",
        right: 0, 
      top: 0,
      transform: "translateX(0)",
      whiteSpace:"nowrap",
      },
      inviteMemberStyle:{
        alignItems: "center",
        display: "flex",
        margin: "0.5rem auto",
        justifyContent: "start",
        gap: "8px",
        paddingLeft:"12px"
      },
      modalButtons: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "1rem",
        marginTop: "2rem",
      },
      filterButton: {
        marginTop:"16px",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        marginBottom:"2rem"
      },
      cancelButton: { background: "#E0EDF0", color: "#325962",
      },
      cancelFilterButton: {
        fontWeight:700,
        fontFamily:"poppins",
        color: "#237182",
        fontSize:"18px"
    
      },
      statusStyle:{
        padding:"4px 6px 4px 8px",
        backgroundColor:"#3B82F6" ,
        fontFamily:'Poppins',
        fontSize:"12px",
        color:"#FFFFFF",
         fontWeight:400 ,
         borderRadius:"40px",
    
      },
      filterStyle:{
        BorderColor:"#CBD5E1",
        borderRadius:"8px",
         display:"flex",
         backgroundColor:"white",
         alignItems:"center",
         width:"7%",
         padding:"10px 12px",
         paddingRight:"25px",
      },
      containerDataStyle: {
        display: "flex",
        color: "#64748B",
        alignItems: "center",
        gap: "4px",
      },
      priorityStyle: {
        width: "69px",
        borderRadius: "16px",
        padding: "1px 8px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        justifyContent: "center",
      },
      priorityStyleList: {
        borderRadius: "16px",
        display: "flex",
        alignItems: "center",
        width: "50px",
        padding: "1px 8px",
        justifyContent: "center",
        gap: "8px",
      },
      roomNameStyle: {
        fontWeight: 600,
        marginTop: "10px",
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#334155",
      },
      roomStyleCard: {
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        gap: "8px",
      },
      roomStyleMainCard:{
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
      },
    
      roomStatusCircleStyle: {
        width: "6px",
        backgroundColor: "#60A5FA",
        height: "6px",
        borderRadius: "50%",
      },
      roomTypeStyle: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 600,
        color: "#64748B",
      },
      cardOuterContainer: {
        borderRadius: "12px",
        margin:"1rem 0rem",
        padding: "12px",
        backgroundColor: "#FFFFFF",
        width:"95%"
      },
      mainStatusStyle: {
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
      },
      statusCountStyle: {
        fontWeight: 600,
        alignItems:"center",
        justifyContent:"center",
        display:"flex",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        color: "white",
        fontFamily: "poppins",
        fontSize: "12px",
        textAlign: "center" as "center",
        backgroundColor: "#237182",
      },
    
      statusCircleStyle: {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: "#60A5FA",
        marginLeft:"1rem"
      },
      roomsStatusCircleStyle: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: "#237182",
      },
      outerBoxStatus: {
        marginTop:"2rem",
        backgroundColor: "#FFFFFF",
        width: "95%",
        borderRadius: "8px",
        alignItems: "center",
        padding: "6px 0px",
        display: "flex",
        justifyContent: "left",
        gap: "8px",
      },
      gridCont: {
         paddingLeft:"6.5rem"
      },
    
      headerSection2: {
        display: "flex",
        justifyContent: "space-between",
        marginTop:"1.5rem",
        alignItems: "center",
        fontFamily: "poppins",
        fontSize: "16px",
      },
      outerBox: {
        borderRadius: "5px",
        alignItems: "center",padding: "0px",
        backgroundColor: "white",
        display: "flex",   justifyContent: "space-between",
        margin: "1.625rem 40px 0px 40px", paddingRight: "10px",
        paddingLeft: "10px",
      },
      OptionBox: {
        display: "flex", justifyContent: "start",
      },
      singleOptionBox: { display: "flex",
        backgroundColor: "#F8FAFC", borderRadius: "50px",
        padding: "4px",
      },
    
      PersonalInformationOption: {
        borderRadius: "50px", fontFamily: "Poppins",
        fontWeight: 600, padding: "9px 16px",
        cursor: "pointer", fontSize: "16px",
      },
    
      BusinessInformationOption: {
        padding: "9px 16px", borderRadius: "50px",
        cursor: "pointer", fontFamily: "Poppins",
        fontWeight: 600, margin: "0px",
        fontSize: "16px",
      },
      flex: {
        display: "flex", justifyContent: "space-between",
        alignItems: "center",padding: "0px 40px 0px 40px", marginTop: "32px",
        backgroundColor: "#f0f5f7",
      },
      sort: {
        marginRight: "3px",  fontSize: "14px",
        fontFamily: "Poppins",
        fontWeight: 400, color: "#334155",
      },
      innerDetail:{ display:"flex",
        alignItems:"center", gap:"8px",
        fontSize:"14px"
      }
      
  };