//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Select,
  Grid,
  MenuItem,
  styled,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  Dialog,
  IconButton,
  Popover,
  FormHelperText,
  SvgIcon,
  Divider,
  Modal,
  Paper,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import 'react-circular-progressbar/dist/styles.css';
import { Close, KeyboardArrowDown } from "@mui/icons-material";
import "react-calendar/dist/Calendar.css";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import Calendar from 'react-calendar';
import CloseIcon from '@material-ui/icons/Close';
import TodayIcon from '@mui/icons-material/Today';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dropzone, {DropzoneState} from 'react-dropzone';
import {
  unCheckIcon,
  checkedIcon,
  paidIcon,
  unPaidIcon,
  upComingIcon,
  cross,
  calenderIcon,
  emptyStateImg,
  newFileImg,
  success_icon_toster,
  dowloadImg
} from "./assets";
import { reload,warningIcon } from "../../drawing/src/assets";
import { cancelIcon, } from "../../../blocks/kanbanboard/src/assets";
import { pdfFileIcon, pdfFileWarningIcon } from "../../../blocks/drawing/src/assets";
import { ToastContainer } from "react-toastify";
import SaveAltIcon from '@mui/icons-material/SaveAlt';

// Customizable Area End

import ProjectInvoiceController, {
  Props,
} from "./ProjectInvoiceController";
import { searchIcon } from "packages/blocks/projectportfolio/src/assets";

export default class ProjectInvoice extends ProjectInvoiceController {
  instance: any;
  [x: string]: any;
  instance: any;
  instance: any;
  instance: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInvoiceSummary = () => {
    let invoiceSummary = this.state.invoiceSummary.data
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          borderRadius: "12px",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          margin: "20px 30px",
          padding: "20px 27px",
          alignItems: "center",
          gap: { xs: "20px", md: "0" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: "30px",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "18px",
              fontFamily: "Poppins",
              lineHeight: "26px",
            }}
          >
            Invoice Summary
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ height: "62px", width: "62px" }}>
              <CircularProgressbarWithChildren
                data-testid="progressBar"
                styles={buildStyles({
                  pathColor: "#10B981",
                  trailColor: "#D1FAE5",
                })}
                strokeWidth={"8"}
                value={this.getPaidProgress(invoiceSummary)}
              >
                <img
                  style={{ width: 26, height: 26, marginTop: -5 }}
                  src={paidIcon}
                />
              </CircularProgressbarWithChildren>
            </Box>
            <Box>
              <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
                Paid Invoices:{" "}
                <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
                  {invoiceSummary?.paid_invoices_count}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ height: "62px", width: "62px" }}>
            <CircularProgressbarWithChildren
              styles={buildStyles({
                pathColor: "#FCD34D",
                trailColor: "#FEF3C7",
              })}
              strokeWidth={"8"}
              value={this.getUpcomingProgress(invoiceSummary)}
            >
              <img
                style={{ width: 26, height: 26, marginTop: -5 }}
                src={upComingIcon}
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Box>
            <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
              Upcoming Invoices:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
              {invoiceSummary?.upcoming_invoices_count}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ height: "62px", width: "62px" }}>
            <CircularProgressbarWithChildren
              styles={buildStyles({
                pathColor: "#EF4444",
                trailColor: "#FEE2E2",
              })}
              strokeWidth={"8"}
              value={this.getUnpaidProgress(invoiceSummary)}
            >
              <img
                style={{ width: 26, height: 26, marginTop: -5 }}
                src={unPaidIcon}
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Box>
            <Typography sx={{ ...webStyle.progressText, fontSize: "16px" }}>
              Unpaid Invoices:{" "}
              <span style={{ ...webStyle.progressNumber, fontSize: "18px" }}>
                {invoiceSummary?.unpaid_invoices_count}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  renderTableUperDiv = () => {
  return (
    <Box sx={webStyle.flex}>
      <Box sx={webStyle.searchBox}>
        <TextField
          variant="outlined"
          placeholder="Search here..."
          data-test-id="search-input"
          value={this.state.searchInputText}
          onChange={(e) => this.handleSearchInputChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{ height: "20px", color: "#94A3B8", width: "20px" }}
                />
              </InputAdornment>
            ),
            endAdornment: this.state.searchInputText && (
              <InputAdornment position="end">
                <IconButton
                  onClick={this.clearSearch}
                  data-test-id="clear_search_id"
                  disableRipple
                  style={{ padding: 0, cursor: "pointer" }}
                >
                  <ClearIcon
                    style={{
                      color: "#94A3B8",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #CBD5E1",
              width: { xs: "100%", sm: "220px" },
              "& ::placeholder": {
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#94A3B8",
              },
            },
          }}
          style={{
            borderRadius: "8px",
            backgroundColor: "white",
          }}
        />
        <Box
          style={{
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
          }}
        >
            <Button
              testID="bulkButton"
            sx={{
              backgroundColor: "#F1F5F9",
              height: "32px",
              border: "1px solid #CBD5E1",
              borderRadius: "8px",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 400,
              color: "#94A3B8",
              textTransform: "none",
            }}
            variant="outlined"
            endIcon={<KeyboardArrowDown />}
            onClick={this.handleMenuOpen}
          >
            Bulk Action
          </Button>
          <Menu
            data-test-id="handleMenuCloseId"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "8px",
              },
              "& .MuiMenu-list": {
                padding: "0px 0px",
              },
            }}
          >
            <MenuItem
              testId="openDownloadPopup"
              onClick={() => this.openDownloadModal()}
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 400,
                fontFamily: "Poppins",
                color: "#0F172A",
                width: "144px",
                height: "46px",
                borderRadius: "8px",
              }}
            >
              Download
            </MenuItem>
          </Menu>
        </Box>
        <Typography sx={{ fontWeight: 400, fontSize: "12px", marginLeft: "15px",

         }}>
          *Open the invoice to change the status
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          '@media (max-width: 1127px)': {
            marginTop: "10px"
          },
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
        Retention Percentage:  <span style={{color:"#0F172A",fontWeight: 600, }}>{this.state.Invoice_Summarydata.retention_percentage}%</span>
        </Typography>
      </Box>
    </Box>
  );
  };
  renderMembersList = () => {
    const overAllProjectInvoice = this.state.invoiceData
    const isChecked = this.state.isClientChecked
    return overAllProjectInvoice.map((invoiceList, index) => (
        <TableRow key={index}>
          <TableCell style={webStyle.SubDivC}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Checkbox
                data-test-id="checkbox-change-data-id"
                icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
                checkedIcon={
                  <img src={checkedIcon} style={{ width: 16, height: 16 }} />
                }
                checked = {isChecked}
                style={{ color: "#64748B" }}
              />
              <Typography style={{...webStyle.SubDivC, cursor: 'pointer',}} onClick={this.handleInvoiceOverview.bind(this,invoiceList)}>
                {invoiceList.attributes.invoice_number.invoice_name}
              </Typography>
            </Box>
          </TableCell>
          <TableCell
            style={{
              ...webStyle.SubDivC
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
              }}
            >
              £{invoiceList.attributes.total_amount}
            </Typography>
          </TableCell>
          <TableCell style={{ ...webStyle.SubDivC }}>
            {this.getFormatedDueDate(invoiceList.attributes.due_date)}
          </TableCell>
          <TableCell style={{ ...webStyle.SubDivC, }}>
            {invoiceList.attributes.payment_status === "paid" &&(

             <Typography sx={{
              width: "fit-content",
              paddingBlock: "2px",
              paddingInline: "8px",
              fontWeight: 700,
              textTransform:"uppercase",
              backgroundColor: "#D1FAE5",
              borderRadius: "20px", display: "flex", alignItems: "center",
              color: "#059669"
            }}>
              {invoiceList.attributes.payment_status}
            </Typography>
            )
            }
                {invoiceList.attributes.payment_status === "unpaid" &&(
             <Typography sx={{
              width: "fit-content",
              paddingBlock: "2px",
              paddingInline: "8px",
              fontWeight: 700,
              textTransform:"uppercase",
              backgroundColor: "#FEE2E2",
              borderRadius: "20px", display: "flex", alignItems: "center",
              color: "#DC2626",
            }}>
              {invoiceList.attributes.payment_status}
                </Typography>
                )
            
            }
          {invoiceList.attributes.payment_status === "upcoming" && (
                <Typography 
                  sx={{
                    paddingInline: "8px",
                    fontWeight: 700,
                    width: "fit-content",
                    paddingBlock: "2px",
                    textTransform: "uppercase",
                    borderRadius: "20px", 
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#FEF3C7",
                    color: "#D97706"
                  }}
                >
                  {invoiceList.attributes.payment_status}
                </Typography>
              )}

          </TableCell>
          <TableCell
            style={{
              ...webStyle.SubDivC, textAlign: "end",
            }}
          >
          <IconButton
            data-test-id="downloadIconTest"
            className="hoverEffect"
          >
            <img src={dowloadImg} style={{ width: "35px", height: "30px", color: "#0F172A" }} />
          </IconButton>
          </TableCell>
        </TableRow>
    ));
  };

  renderTable = () => {
    return (
     <Box sx={{marginRight: "30px", paddingRight: "30px" }}>
       <TableContainer style={webStyle.SubTableBox}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Checkbox
                    data-test-id="clickcheckBoxTest"
                    icon={
                      <img
                        src={unCheckIcon} style={{ width: 16, height: 16 }} />
                    }
                    checkedIcon={<img
                      src={checkedIcon}
                      style={{ width: 16, height: 16 }}
                    />}
                    onChange={this.handleCheckboxClick}
                    style={
                      { color: "#64748B" }
                    }
                  />
                  <Typography style={webStyle.TableTitleText}> Invoice</Typography>
                </Box>
              </TableCell>
              <TableCell style={webStyle.TableTitleText}>Amount</TableCell>
              <TableCell style={webStyle.TableTitleText}>Due Date</TableCell>
              <TableCell style={webStyle.TableTitleText}>Status</TableCell>
              <TableCell style={webStyle.TableTitleText}>{""}</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {this.state.emptyInvoice ? (
                <TableRow>
                  <TableCell colSpan={7} sx={{ textAlign: "center", height: "667px" }}>
                    <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#000" }}>
                      No Invoice Found !
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                this.renderMembersList()
              )}
            </TableBody>
        </Table>
      </TableContainer>
     </Box>
    )
  }

  renderPagination = () => {
    const { rowsPerPage, currentPage, totalMembers } = this.state;
    const totalPages = Math.ceil(totalMembers / rowsPerPage);
    const maxPageButtons = 8;

    const pageNumbers = this.generateMembersPageNumbers(
      currentPage,
      totalPages,
      maxPageButtons
    );

    const handleClickData = (page: any) => {
      if (page !== "...") {
        this.setMembersPage(page);
      }
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
      <Box sx={webStyle.footer}>
        <Box sx={webStyle.footerSecond}>
          <Box sx={{flexGrow:0.9}}></Box>
          <Box style={webStyle.footerThird}>
            <ChevronLeftIcon
              style=
              {
                {
                  ...webStyle.footerIcon,
                  width: "24px",
                  height: "24px",
                  color: isFirstPage ?"#475569": "#64748B"
                }
              }
              onClick={!isFirstPage ? this.handlePreviousMemberPage : undefined}
              data-test-id="previousPage"
            />
            {
              pageNumbers.map((page, index) =>
              (
                <Box key={index}
                  sx={{ marginLeft: "8px", marginRight: "8px" }}>
                  <Typography
                    data-test-id="pageNumber"
                    style={
                      {
                        ...webStyle.footerFont,
                        cursor: page === "..." ?
                          "default" :
                          "pointer",
                        "bold": "normal",
                        fontWeight: page === this.state.currentPage ? 600 : 400,
                        fontSize: page === this.state.currentPage ? "16px" : "12px",
                        color: page === this.state.currentPage ? "#325962" : "#000",
                      }}
                    onClick={() => handleClickData(page)}
                  >
                    {page}
                  </Typography>
                </Box>
              ))}
            <ChevronRightIcon
              style={{
                ...webStyle.footerIcon,
                width: "24px",
                height: "24px",
                color: isLastPage ? "#64748B": "#475569"
              }}
              onClick={!isLastPage ? this.handleNextMemberPage : undefined}
              data-test-id="nextButton"
            />
          </Box>
          <Typography style={webStyle.footer3Style}>
          {`${(currentPage - 1) * rowsPerPage + 1} -  ${currentPage === totalPages ? totalMembers : currentPage * rowsPerPage}  of ${totalMembers} results`}
          </Typography>
        </Box>
      </Box>
    );
  };

  renderDownloadModal = () => {
    return (
      <AddTaskDialog
        data-test-id="downlaodModal"
        onClose={this.closeDownloadModal}
        open={this.state.openDownloadModal}
      >
        <Box sx={{margin: "10px"}}>
          <Box sx={{display: "flex", }}>
            <Typography
              sx={{
                color: "#0F172A",
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 700,
                fontFamily: "Poppins",
              }}
            >
              Do you want to download your invoices?
            </Typography>
            <img onClick={this.closeDownloadModal}
                  style={{ width: 11, height: 11, marginTop: "8px", marginLeft: "8px"}}
                  src={cross}
                />
          </Box>
          <Typography
            sx={{
              color: "#1E293B",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Poppins",
              marginTop: "10px"
            }}
          >
            Click 'Download' to save your invoices to your device.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              flexWrap: "wrap",
              marginTop: "30px"
            }}
          >
            <Button
              onClick={this.closeDownloadModal}
              data-test-id="cancelButton"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#E0EDF0",
                color: "#325962",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                lineHeight: "24px",
                padding: "10px 16px",
                textTransform: "none"
              }}
            >
              Cancel
            </Button>
            <Button
              data-test-id="downloadButton"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#237182",
                color: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: 600,
                fontSize: "16px",
                borderRadius: "8px",
                padding: "10px 16px",
                textTransform: "none"
              }}
            >
              Download
            </Button>
          </Box>
        </Box>
      </AddTaskDialog>
    );
  };

  renderCreateInvoiceModal = () => {
    return (
      <CreateInvoiceDialog
        testID="createInvoiceModal"
        onClose={this.closeCreateInvoiceModal}
        open={this.state.openCreateInvoiceModal}
      >
         <Grid container spacing={2} >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "24px", fontWeight: 700, color: '#0F172A', fontFamily: 'Poppins' }}>
            Create Invoice
          </span>
          <CloseIcon
            style={{ cursor: "pointer", width: '20px', height: '20px' }}
            onClick={this.closeCreateInvoiceModal}
          />
        </Grid>
        <Grid item xs={12}>
        <label htmlFor="Task" style={webStyle.addTaskPopupHeadings}>Invoice Name <span style={{ color: "#DC2626", position: "relative", top: "4px", fontSize: "18px"}}>*</span></label>
          <TextField
            fullWidth
            id="invoiceName"
            data-test-id={"invoiceName"}
            name="invoiceName"
            placeholder="Invoice 001"
            variant="outlined"
            value={this.state.invoiceNameCI}
            onChange={this.handleInvoiceNameCI}
            error={Boolean(this.state.invoiceError || this.state.invoiceNameLimitError)} 
              helperText={this.state.invoiceError || this.state.invoiceNameLimitError}
              InputProps={{
                sx: {
                  "& input::placeholder": {
                    color: "#94A3B8",
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                  },
                  
                  "& input": {
                    color: "#0F172A",
                    fontFamily: "Poppins",
                  },
              },
            }}
            sx={webStyle.textFieldError}
          />
        </Grid>
        <Grid item xs={6}>
            <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>Amount (in £)</label>
            <TextField
              fullWidth
              id="labourCostUnit"
              data-test-id={"labourCostUnitNew"}
              name="labourCostUnit"
              placeholder="5000.00"
              variant="outlined"
              value={this.state.amountCI}
              onChange={this.handleAmountCI}
              error={!!this.state.amountError || !!this.state.amountErrorBlank} 
              helperText={this.state.amountError || this.state.amountErrorBlank}
              InputProps={{
                disableUnderline: true,
                sx: {
                  "& input::placeholder": {
                    color: "#94A3B8",
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                  },
                  "& input": {
                    color: "#0F172A",
                    fontFamily: "Poppins",
                  },
                },
              }}
              sx={webStyle.textFieldError}
            />
          </Grid>
          <Grid item xs={6}>
            <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>
              Due Date
            </label>
            <Box
              component="div"
              onClick={this.openDueDateCalendar}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                border: `1px solid ${this.state.dueDateErrorCI?.length > 0 ? "red" : "rgb(209 224 242)"}`,
                padding: "14px 14px 14px 8px",
                mt: "4px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <Typography sx={this.state.dueDateCI ? webStyle.dynamicDate : webStyle.displayText}>
                {this.state.dueDateCI
                  ? new Date(this.state.dueDateCI).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  })
                  : "31/12/24"}
              </Typography>
              <TodayIcon sx={{ color: "#94A3B8", marginLeft: "8px" }} />
            </Box>

            {this.state.dueDateErrorCI?.length > 0 && (
              <Typography sx={{ color: "rgb(211, 47, 47)", fontSize: "12px", mt: "4px" }}>
                {this.state.dueDateErrorCI}
              </Typography>
            )}

            <Popover
              data-testid="datePopover"
              anchorEl={this.state.anchorDueDate}
              open={Boolean(this.state.anchorDueDate)}
              onClose={this.closeDueDateCalendar}
              disableAutoFocus
              disableRestoreFocus
              disableScrollLock
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "8px",
                  padding: "16px",
                },
              }}
            >
              <StyledCalendar
                data-test-id="styledduedateCalendar"
                value={this.state.dueDateCI ? new Date(this.state.dueDateCI) : null}
                onChange={(date) => this.handleSelectDueDateCI(date)}
                minDate={new Date()}
              />
            </Popover>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="LabourCostUnit" style={webStyle.addTaskPopupHeadings}>
              Payment Status <span style={{ color: "#DC2626", position: "relative", top: "4px", fontSize: "18px" }}>*</span>
            </label>

            <Box sx={{ ...webStyle.statusContainer }}>
              {["Unpaid", "Upcoming", "Paid"].map((status) => (
                <Typography
                  key={status}
                  sx={{
                    ...webStyle.statusButtons,
                    color: this.state.statusCI === status ? "#0F172A" : "#64748B",
                    backgroundColor: this.state.statusCI === status ? "#D3E4E9" : "transparent",
                    padding: "8px 60px",
                    borderRadius: "60px",
                    transition: "background-color 0.3s ease-in-out",
                  }}
                  onClick={this.handleStatusChange.bind(this, status)}
                >
                  {status}
                </Typography>
              ))}
            </Box>

            {this.state.statusError.length > 0 && (
                            <FormHelperText sx={{ color: "rgb(211, 47, 47)", fontSize: "12px" }}>{this.state.statusError}</FormHelperText>

            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            flexWrap: "wrap",
            marginTop: "30px"
          }}
        >
            <Button
            onClick={this.closeCreateInvoiceModal}
            data-test-id="cancelButton"
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#F1F5F9",
              color: "#64748B",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              borderRadius: "8px",
              lineHeight: "24px",
              padding: "10px 16px",
              textTransform: "capitalize",
              boxShadow : "none",
              "&:hover": {
                color: "#325962",
                backgroundColor: "#E0EDF0", 
              },
            }}
          >
            Cancel
          </Button>
          {this.state.statusCI==="Paid"?
          
          <Button
            data-test-id="uploadBtn"
            variant="contained"
            color="primary"
            onClick={()=>this.handleAddDocModal(true)}
            sx={{
              backgroundColor: "#237182",
              color: "#FFFFFF",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              borderRadius: "8px",
              padding: "10px 16px",
              textTransform: "capitalize",
              boxShadow : "none",
              "&:hover": {
                backgroundColor: "#237182", 
                color: "#FFFFFF",
              },
            }}
          >
           Upload Invoice
          </Button>:
           <Button
           data-test-id="downloadButton"
           variant="contained"
           color="primary"
           onClick={this.handleCreateInvoice}
           sx={{
            backgroundColor : this.state.createButtonHighlight ? "#237182" : "#F1F5F9",
              color: this.state.createButtonHighlight ? "#FFFFFF" : "#64748B",
             fontFamily: "Poppins",
             fontWeight: 600,
             fontSize: "16px",
             borderRadius: "8px",
             padding: "10px 16px",
             textTransform: "capitalize",
             boxShadow : "none",
             "&:hover": {
              backgroundColor: this.state.createButtonHighlight ? "#237182" : "#F1F5F9",
              color: this.state.createButtonHighlight ? "#FFFFFF" : "#64748B",
             },
           }}
         >
            Create
         </Button>
        }
        </Box>
      </CreateInvoiceDialog>
    );
  };

  renderIconoColorStyle = (status: string | undefined) => {
    if (!status) return {fontSize: 20}
    else if (status === 'upcoming') return  {color: '#D97706', fontSize: 20}
    else  if (status === 'paid') return  { fontSize: 20,color: '#059669',}
    else  return { color: '#DC2626',fontSize: 20}
  }

  CustomSvgIcon(props:  object) {
    return (
      <SvgIcon {...props}>
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </SvgIcon>
    );
  }

 renderEmptyState = () => {
  const { invoicePaymentStatus } = this.state;
  const { totalPages, zoom } = this.state;
    const availableOptions = {
      upcoming: [
        { value: 'unpaid', label: 'Unpaid', style: styles.unpaid },
        { value: 'paid', label: 'Paid', style: styles.paid },
      ],
      unpaid: [
        { value: 'paid', label: 'Paid', style: styles.paid },
      ],
      paid: [], // No options if already paid
    };
  return (
    <Grid container sx={{width: "100%"}}>
      <Grid item xs={9} 
        sx={{
          backgroundColor: "#F0F5F7",
          padding: "20px 32px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
            <Box sx={{ display: "flex", cursor: 'pointer' }} onClick={this.handleBackClick}>
              <ChevronLeftIcon style={{ color: "#334155" }} />
              <Typography sx={{ ...styles.headerTypo, color: "#334155" }}>Back</Typography>
            </Box>
            <Box sx={{
              display: "flex", gap: "12px",
            }}>
              {this.state.invoiceDocument.length > 0 &&
                this.state.invoiceDocument.map((invoice: any, index: number) => {
                  const isSelected = this.state.clickedFileName === invoice.filename;

                  return invoice.filename ? (
                    <TabButton
                      key={index}
                      style={{
                        backgroundColor: isSelected ? "#D3E4E9" : "#E2E8F0",
                        border: isSelected ? "1px solid #325962" : "none",
                      }}
                      onClick={
                        this.loadPDF.bind(this,invoice.url, invoice.filename, invoice.attachment_id, invoice.url)
                      }
                    >
                      {invoice.filename.length > 5
                        ? invoice.filename.substring(0, 5) + "..."
                        : invoice.filename}
                    </TabButton>
                  ) : null;
                })}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {this.state.role !== 'client' && (
              <>
            <Box sx={{ pr: "20px", display: "flex", gap: "8px" }}>
              <img src={newFileImg} height={"20px"} width={"20px"} style={{ cursor: 'pointer' }} onClick={this.handleNewFileUpload} />
              <Typography sx={{ ...styles.headerTypo, color: "#237182" }}> New File</Typography>
            </Box>
            <Box sx={{ pr: "20px", display: "flex", gap: "8px" }}>
              <DeleteOutlineIcon
                sx={{
                  height: "20px",
                  width: "20px",
                  color: this.state.attachmentId ? "#237182" : "#94A3B8",
                  cursor: this.state.attachmentId ? "pointer" : "not-allowed",
                }}
                onClick={this.state.attachmentId ? this.handleDeleteModal : undefined}
              />
              <Typography
                sx={{
                  ...styles.headerTypo,
                  color: this.state.attachmentId ? "#237182" : "#94A3B8",
                }}
              >
                Delete File
              </Typography>
            </Box>
              </>
            )}
            
            <Box>
              {this.state.role !== "client" ? (
                <Select
                  value={invoicePaymentStatus}
                  IconComponent={this.CustomSvgIcon}
                  onChange={this.handlePaymentStatusChange}
                  sx={{
                    ...styles.select,
                    '& .MuiSelect-icon': this.renderIconoColorStyle(invoicePaymentStatus),
                    '& .MuiSelect-select': {
                      paddingRight: '4px !important',
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "8px !important",
                        top: "125px !important",
                        width: "115px !important",
                        "& .MuiList-root": {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingInline: "6px",
                          border: "1px solid #E2E8F0",
                          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
                        },
                      },
                    },
                  }}
                  data-test-id="project-select-option"
                  displayEmpty
                  renderValue={(selected) => (
                    <Typography sx={{ ...styles[selected], padding: '2px 25px 2px 8px !important' }}>
                      {selected.charAt(0).toUpperCase() + selected.slice(1)}
                    </Typography>
                  )}
                >
                  {["upcoming", "unpaid", "paid"]
                    .filter((option) => option !== invoicePaymentStatus)
                    .map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        disableRipple
                        sx={{
                          textAlign: "center",
                          padding: "4px 12px !important",
                          backgroundColor: "transparent !important", // Prevent default highlight
                          "&.Mui-focusVisible": { backgroundColor: "transparent !important" }, // Prevent focus style
                          "&:hover": { backgroundColor: "#F8FAFC !important" }, // Custom hover effect
                          marginBottom: "7px",
                          "&:last-child": { marginBottom: 0 },
                        }}
                      >
                        <Typography sx={{ ...styles[option], padding: 0 }}>
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography sx={{ ...styles[invoicePaymentStatus], padding: '2px 8px' }}>
                  {invoicePaymentStatus.charAt(0).toUpperCase() + invoicePaymentStatus.slice(1)}
                </Typography>
              )}
            </Box>

          </Box>
        </Box>
        {this.state.invoiceDocument.length > 0 ? (
          <Box className="pdfSection">
            <div className="pdf-viewer">
              <div style={{ position: "relative" }}>
                <div className="canvas-container">
                  <canvas ref={this.canvasRef} style={{ width: "100%", marginTop: "20px" }}></canvas>

                </div>
              </div>
            </div>
            <Box style={{
              position: "fixed",
              bottom: "12px",
              left: "40%",
              transform: "translateX(-60%)",
            }}>
              <div className="pdf-controls" style={{
                display: "flex", alignItems: "center", backgroundColor: "#F8FAFC", paddingInline: "20px",
                boxShadow: `0px 8px 32px rgba(0, 0, 0, 0.06), 
              0px 4px 8px rgba(0, 0, 0, 0.03), 
              0px 25px 50px rgba(0, 0, 0, 0.09)`,
                position: "sticky",
              }}>
                <Typography className="toolbar">
                  <ActionButton data-test-id="handleZoomIn" className="buttons" onClick={this.handlePrevPage.bind(this)}>prev</ActionButton>
                  <span className="activePage " style={{ margin: "10px ", border: "1px solid #CBD5E1", borderRadius: "8px", background: "#FFFFFF", padding: "6px", paddingRight: "8px" }}> {this.state.currentPagepdf}</span> /  <span style={{ margin: "10px " }}> {totalPages}</span>
                  <ActionButton data-test-id="handleZoomIn" className="buttons" onClick={this.NextPage.bind(this)}>next</ActionButton>
                </Typography>
                <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', margin: '16px' }} />
                <ActionButton data-test-id="handleZoomOut" className="buttons" style={{ margin: '5px' }} onClick={this.handleZoomOut}>-</ActionButton>
                <p className="activePage zoom_percenage" style={{ margin: "10px " }}>{(zoom * 100).toFixed(0)}%</p>
                <ActionButton data-test-id="handleZoomIn" className="buttons" onClick={this.handleZoomIn}>+</ActionButton>
                <Box style={{ width: '1px', background: '#E2E8F0', height: '35px', borderRadius: '1px', margin: '16px' }} />

                <ActionButton data-test-id="download" className="buttons" onClick={this.handleDownload}>
                  <SaveAltIcon style={{ width: '18px', height: "18px", color: "#475569" }} />
                </ActionButton>
              </div>
            </Box>
          </Box>
        ) : (
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            padding: "138px 0px",
            alignItems: "center"
          }}>
            <Box>
              <Box>
                <img src={emptyStateImg} height={"400px"} width={"400px"} />
              </Box>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}>
                <Typography sx={{ ...styles.nofileUploadTypo }}>No file has been uploaded yet</Typography>
                <Typography sx={{ ...styles.nofileUploadDescTypo }}>Upload an invoice file to view details here.</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs={3} sx={{ backgroundColor: "#FFFFFF",}}>
        <Box>
          <Box sx={{borderBottom: "1px solid #E2E8F0", padding: "20px 0px 20px 20px", }}>
            <Typography sx={{ color: "#0F172A", fontFamily: "Poppins", fontSize: "18px", lineHeight: "26px", fontWeight: 700 }}>Invoice #{this.state.invoiceNameTitle.length > 15
              ? this.state.invoiceNameTitle.substring(0, 15) + "..."
              : this.state.invoiceNameTitle}</Typography>
          </Box>
          <Box sx={{
            borderBottom: "1px solid #E2E8F0", padding: "16px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "18px",
          }}>
            <Box>
              <Typography sx={{...styles.invoiceDetails}}>Invoice Value:</Typography>
              <Typography sx={{...styles.invoiceTypo}}>£{this.state.invoiceValue}</Typography>
            </Box>
            <Box>
              <Typography sx={{...styles.invoiceDetails}}>Due Date:</Typography>
              <Typography sx={{...styles.invoiceTypo}}>{this.state.dueDate}</Typography>
            </Box>
            <Box>
              <Typography sx={{...styles.invoiceDetails}}>Client Name:</Typography>
              <Typography sx={{...styles.invoiceTypo}}>{this.state.clientName}</Typography>
            </Box>
            <Box>
              <Typography sx={{...styles.invoiceDetails}}>Project Name:</Typography>
              <Typography sx={{...styles.invoiceTypo}}>{this.state.ProjectName}</Typography>
            </Box>
            <Box>
              <Typography sx={{...styles.invoiceDetails}}>Client Contact:</Typography>
              <Typography sx={{...styles.invoiceTypo}}>{this.state.clientEmail}</Typography>
              <Typography sx={{...styles.invoiceTypo}}>{this.state.clientNumber}</Typography>
            </Box>
            <Box>
              <Typography sx={{...styles.invoiceDetails}}>Client Address:</Typography>
              <Typography sx={{...styles.invoiceTypo}}>{this.state.clientAddress}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
 }
 
  addFolderModal = () => {
    return (
      <Modal
        open={this.state.addDocsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box style={webStyle.uploadModal}>
          <Box display={"flex"}>
          <Typography style={webStyle.fontStyles("24px",700,"#0F172A")}>Upload Files</Typography>
         </Box>
          <Dropzone
          onDrop={(acceptedFiles) => {
            this.updatePdfFile(acceptedFiles);
          }}
          data-test-id="dropzone"
            >
              {({ getRootProps, getInputProps }: DropzoneState) => (
          <Paper
            elevation={0}
            sx={webStyle.dropzonePaper}
            {...getRootProps({
              style: {
                cursor: 'pointer',
              },
            })}
          >
                <input {...getInputProps()} id="hiddenFileInput" />
              <>
                <label htmlFor="contained-button-file" style={{ ...webStyle.label, color: '#334155' }}>
                  <Typography variant="body1" style={webStyle.fontStyles("14px",600,"#292524")}>Drag & Drop Files Here</Typography>
                </label>
                <Typography variant="body1">
                  <Typography style={webStyle.labelText}>
                  Acceptable formats: JPG, PDF, PNG
                  </Typography>
                  <Typography style={webStyle.labelText}>(Max size: 10MB)</Typography>
                </Typography>
              </>
          </Paper>
               )}
          </Dropzone>
          {this.state.uploadedFiles && this.renderFileBlock(this.state.fileMetadata)}
          <Box display={"flex"} gap={"16px"}>
         <Button variant="contained" data-test-id="cancelUpload" style={webStyle.cancelBtn} onClick={this.handleAddDocModal.bind(this, false)}
         >
         Cancel
         </Button>
         <Button variant="contained" data-test-id="submit-upload" style={this.validateUpload()?webStyle.uploadModalBtn:webStyle.activeUploadBtn} disabled={this.validateUpload()} 
              onClick={() => { this.state.isInvoiceOverviewDoc ? this.handleInvoiceDoc() : this.handleUploadApi() }}>
         Upload
         </Button>
         </Box>
        </Box>
      </Modal>
    )
  }
  renderFileBlock = (fileMetaData:any) => {
    if (!fileMetaData || fileMetaData.length === 0) {
      return null;
    }
    return (
      <>
        {this.state.errorMessage && (
          <Box style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginTop: '6px' }}>
            <img src={warningIcon} alt="" />
            <Typography
              style={{
                color: '#DC2626',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
                textAlign: 'left',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
              }}
            >
              {this.state.errorMessage}
            </Typography>
          </Box>
        )}
<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography sx={{
          color: '#334155',
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
        }}>Attached Files:</Typography>
        
           <Typography style={webStyle.fontStyles("16px",400,"#334155")}>Files:<span style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins",color:"#237182"}}></span>{this.state.uploadedFiles.length}</Typography>
        </Box>
        {fileMetaData.map((fileData:any, index:any) => (
          <Paper
            key={index}
            style={{
              boxShadow: "rgba(0.1, 0, 0, 0.1) 0px 4px 5px 5px",
              borderRadius: "8px",
              padding: "6px 8px",
              marginTop: "8px",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: fileData.errorMessage ? "2px solid #DC2626" : "2px solid #34D399",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}>

              <img src={fileData.errorMessage? pdfFileWarningIcon : pdfFileIcon} alt="pdfFileIcon" />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography className="fileNameTypo">{fileData.name}</Typography>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    textAlign: "left",
                    color: fileData.errorMessage ? "#DC2626" : "#1E293B",
                  }}
                >
                  {fileData.errorMessage || fileData.size}
                </Typography>
              </Box>
            </Box>

            <Box style={{ display: "flex", gap: 8 }}>
              {fileData.errorMessage && (
                <Box 
                data-test-id = "removeUploadFIleTest"
                onClick={this.handleRemoveUploadedFile.bind(this, fileData.name)}
                >
                  <img src={reload} style={{ marginTop: "5px", height: 18, width: 18, cursor: "pointer" }} />
                </Box>
              )}
              <Box data-test-id="handleRemove"
               onClick={this.handleRemoveUploadedFile.bind(this, fileData.name)}
               >
                <img src={cancelIcon} alt="cancelIcon" />
              </Box>
            </Box>
          </Paper>
        ))}
      </>
    );
  };
  
  renderDeleteTeamModal = () => {
    return (
      <Modal
        open={this.state.isDeleteModalOpen}
        data-test-id="handleDeleteModal"
        onClose={this.handleDeleteModal}
        style={{ display: "flex" }}
      >
        <Box style={deleteModal.renameModal}>
          <Box style={deleteModal.modalHeader}>
            <Typography style={deleteModal.renameText}>
            Are you sure you want to delete {this.state.clickedFileName}?
            </Typography>
            <Close
              onClick={this.handleDeleteModal}
              style={{ cursor: "pointer" }}
              data-test-id="close-btn"
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
          This action cannot be undone.
          </Typography>
          <Box style={deleteModal.modalButtons}>
            <RenameButton
              style={deleteModal.cancelButton}
              onClick={this.handleDeleteModal}
              data-test-id="cancel-btn"
            >
              Cancel
            </RenameButton>
            <RenameButton
              data-test-id="delete-btn"
              onClick={this.handleDeleteDocument}
            >
              Delete
            </RenameButton>
          </Box>
        </Box>
      </Modal>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Box style={{ background: "#f0f6f7", height: "100%", }}>
               <MyProfileHeader showSidebar={!this.state.isInvoiceOverviewOpen} navigation={this.props.navigation}>
               {!this.state.isInvoiceOverviewOpen ? (
    <Box style={{ paddingLeft: "6.5rem" }}>
    <Box
    sx={{
      display: "flex",
      flexDirection: { xs: "column", md: "row" },
      borderRadius: "12px",
      justifyContent: "space-between",
      margin: "20px 30px",
      alignItems: "center",
      gap: { xs: "20px", md: "0" },
    }}
  >

    <Typography
      sx={{
        color: "#0F172A",
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 700,
        fontFamily: "Poppins",
      }}
    >
      {this.state.ProjectName}
    </Typography>
    <Box style={{display:"flex",alignItems:"center",}}>
    <Typography sx={{ fontWeight: 400, fontSize: "14px",marginRight:"10px" }}>
    Total Invoices:  <span>{this.state.Invoice_Summarydata.total_invoices_count}</span>
</Typography>
{this.state.role !== 'client' && (
<Button
    testID="createInvoiceButton"
  onClick={this.openCreateInvoiceModal}
  variant="contained"
  color="primary"
  sx={{
    backgroundColor: "#237182",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    height: "44px",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "8px",
    padding: "10px 12px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#237182",
    },
  }}
>
  <span style={webStyle.createIcon}>
    <AddCircleOutlineOutlinedIcon />
  </span>
  Create Invoice
</Button>
)}
    </Box>
</Box>
          {this.renderCreateInvoiceModal()}
          {this.addFolderModal()}
          {this.renderDownloadModal()}
          {this.renderInvoiceSummary()}
          {this.renderTableUperDiv()}
          {this.renderTable()}
          {this.renderPagination()}
        </Box>
          ) : (
              <>
                {this.renderEmptyState()}
                {this.addFolderModal()}
                {this.renderDeleteTeamModal()}
              </>
              )}
          <CustomToast />
    </MyProfileHeader>
  </Box>
);
// Customizable Area End
  }
}

// Customizable Area Start
const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});
const deleteModal = {
  renameText: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "16px",
  },
};

const TabButton = styled(Button)({
  padding: "4px 12px",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "16px",
  color: "#0F172A",
  fontWeight: 400,

});

const MainWrapper = styled(Box)(({ theme }) => ({
  "& .mainWrapper": {
    display: "flex",
    width: "100%",
    hight: "100%",
    backgroundColor: "rgb(240, 245, 247)",

  },
  "& .pdfsection": {
    margin: "20px ",
    marginBottom: "0px",
    padding: " 20px ",
    paddingBottom: "0px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    overflowY: "scroll",
    height: " calc(100vh - 195px)",
    position: 'relative',
    background: "#ffffff",
    overflowX: "hidden",
    scrollbarWidth: "auto",
    scrollbarColor: " #CBD5E1 #ffffff",

    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CBD5E1",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F1F5F9",
    },


  },


  "& .DetailSection": {
    minWidth: "360px",
    width: "360",
    height: " calc(100vh - 88px)",
    scrollbarWidth: "auto",
    scrollbarColor: " #CBD5E1 #ffffff",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CBD5E1",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F1F5F9",
    },
  },
  "& .acc_parent_wrapeer": {
    height: "calc(100% - 44px)",
    overflow: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .backButtonTypo": {
    color: '#334155',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
  },
  "& .tabButton_Wrapper": {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "10px"
  },
  "& .canvas": {
    flex: 1,
    position: 'relative',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .pdfCanvas": {
    width: '90%',
    height: '90%',
    border: '1px solid #ccc',
  },
  '& .pdf-controls': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    background: '#F8FAFC',
    boxShadow: '0px 8px 32px 0px #0000000F',
    padding: '0px 20px',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    height: '45px'
  },
  '& .pdf-viewer': {
    position: 'relative',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .canvas-container:': {
    overflow: 'auto',
    width: '100%',
  },
  "& .zoom_percenage": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    background: "#FFFFFF",
    padding: "10px 8px",
  }
}))

const ActionButton = styled(Button)({
  border: "none",
  background: "transparent",
  padding: "0px",
  minWidth: "unset"
})

const selectOptions = {
  display: 'flex', 
  alignItems: 'center', 
  textAlign: 'center', 
  justifyContent : "center",
  fontSize: '14px', 
  borderRadius: 40, 
  textTransform: 'upperCase', 
  fontWeight: 700,
  padding: '2px 16px 2px 8px',
  '&.MuiTypography-root':{fontFamily: 'Poppins', padding: "4px 12px"},
}

const styles = {
  select: { 
    outline: 'none',
    paddingRight: '0px',
    cursor: 'pointer',
     textOverflow: 'clip !important',
     '& .MuiInputBase-root':{
       borderRadius: '18px',
       border: 'none',
       maxWidth: '180px',
       paddingRight: '0px',
     },
     '& .MuiSelect-select':{
       textOverflow: 'clip !important',
       overflow: 'none',
       marginRight : "5px !important",
       fontWeight: 700,
       paddingRight: '0px',
     },
     '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':{
       padding: '0px',
     },
     "& fieldset": { border: 'none' },
  },
  upcoming: {
    ...selectOptions,
    bgcolor: '#FEF3C7', 
    color: '#D97706',
    "&:hover":{
      bgcolor: '#FEF3C7', 
    },
  },
  paid: {
    ...selectOptions,
    bgcolor: '#D1FAE5', 
    color: '#059669',
    "&:hover":{
      bgcolor: '#D1FAE5', 
    }
  },
  unpaid: {
    ...selectOptions,
    bgcolor: '#FEE2E2', 
    color: '#DC2626',
    "&:hover":{
      bgcolor: '#FEE2E2', 
    }
  },
  headerTypo : {
    fontFamily : "Poppins",
    fontSize : "16px",
    lineHeight : "24px",
    fontWeight : 600,
  },
  nofileUploadTypo : {
    fontFamily : "Poppins",
    fontSize : "24px",
    lineHeight : "32px",
    fontWeight : 700,
    color: "#0F172A"
  },
  nofileUploadDescTypo : {
    fontFamily : "Poppins",
    fontWeight : 400,
    lineHeight : "24px",
    fontSize : "16px",
    color: "#0F172A"
  },
  invoiceDetails: {
    fontSize : "16px",
    fontFamily : "Poppins",
    lineHeight : "24px",
    fontWeight : 600,
    color: "#334155"
  },
  invoiceTypo : {
    fontSize : "14px",
    fontFamily : "Poppins",
    lineHeight : "22px",
    fontWeight : 400,
    color: "#1E293B"
  }
}

const webStyle = {
  fontStyles:(size:string,weight:number,color:string="black")=>{
    return {
     fontFamily:"Poppins",
     fontWeight:weight,
     fontSize:size,
     color:color
    }
  },
  uploadModal:{
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    textAlign: "center",
    width:"552px",
    minHeight:"370px",
    padding:"24px",
    borderRadius:"12px",
    backgroundColor:"#FFFFFF",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    boxSizing:"border-box"
  }as const,
  cancelBtn:{
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Poppins",
    color:"#325962",
    backgroundColor:"#E0EDF0",
    borderRadius:"8px",
    padding:"16px",
    marginLeft:"auto",
    textTransform:"capitalize",
    boxShadow:"none"
  }as const,
  uploadModalBtn:{
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Poppins",
    color:"#64748B",
    backgroundColor:"#F1F5F9",
    borderRadius:"8px",
    padding:"16px",
    textTransform:"capitalize",
    boxShadow:"none"
  }as const,
  activeUploadBtn:{
    backgroundColor:"#237182",
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Poppins",
    color:"#ffffff",
    borderRadius:"8px",
    padding:"16px",
    textTransform:"capitalize",
    boxShadow:"none"
  }as const,
  dropzonePaper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '4px 8px',
    flexDirection: 'column' ,
    backgroundColor:  '#F8FAFC',
    border: '2px dashed rgba(203, 213, 225, 1)',
    margin: '0 auto',
    marginTop: '10px',
    height: "140px",
    borderRadius: "2px",
    boxSizing: 'border-box',
  },
  labelText: {
    fontFamily: "Poppins",
    lineHeight: '22px',
    color: '#334155',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',}as const,
  label: {
      verticalAlign: "middle",
      color: "#237182",
      cursor: 'pointer',
      gap: "5px",
      display: "inline-block",
      fontFamily: "Poppins"
    },
    uploadCrossIcon:{
      marginLeft:"auto",
      width:"20px",
      height:"20px",
      cursor:"pointer"
    },
    addFilesContainer:{
      display:"flex",
      flexDirection:"column",
      marginTop:"1rem",
      gap:"8px",
      marginBottom:"2rem"
    }as const,
    fileCard:{
      display:"flex",
      gap:"12px",
      position:"relative",
      alignItems:"center",
      minHeight:"50px",
      maxHeight:"50px",
      padding:"6px",
      borderRadius:"8px",
      backgroundColor: "#fff",
      overflow:"hidden",
      boxShadow: '0px 8px 32px 0px #0000000F'
  }as const,
  filesContainer:{
    display:"flex",
    flexDirection:"column",
    gap:"8px",
    maxHeight:"200px",
    padding: "8px",
    margin: "-8px",
    overflow:'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }as const,
  statusButtons: {
    fontFamily: "Poppins", fontWeight: 600, fontSize: "16px", lineHeight: "24px", cursor: "pointer"
  },
  statusContainer : {
    display: "flex", justifyContent: "space-around" , mt : "4px", borderRadius : "50px", py : "10px", backgroundColor: "#F8FAFC"
 },
  textFieldError: {
    "& .MuiFormHelperText-root": {
      marginLeft: 0
    }
  },
  dueDateContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    alignItems: "center",
    marginLeft: "8px",
    padding: "4px 8px"
  },

  displayText: {
    color: "#94A3B8",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    marginLeft: "8px"
  },

  filterDataContainer: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "200px",
    width: "100%",
    paddingRight: "10px"
  },

  searchInputStyle: {
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "white",
    height: "32px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "32px",
      minHeight: "32px",
      "& fieldset": {
        borderColor: "#CBD5E1",
      },
      "& input": {
        padding: "5px 10px",
        height: "32px",
      },
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CBD5E1",
      },
    },
    "& ::placeholder": {
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#94A3B8"
    }
  },

  dynamicDate: {
    color: "#0F172A",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "24px"
  },

  rangeStaticText: {
    color: "#475569",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "22px"
  },

  footerFont: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3Style: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },
  footer: {
    bottom: 0,
    left: 0,
    right: 0,
    padding: "20px 40px",
    boxShadow: 1,
  },
  footerSecond: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media (max-width: 450px)': {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
      flexDirection: "column"
    },
  },
  footerThird: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-start",
    color: "#94A3B8",
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  progressNumber: {
    fontWeight: 700,
    fontFamily: "Poppins",
    lineHeight: "26px"
  },

  progressText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",

  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
    marginTop: "32px",
    marginBottom: "20px",
    backgroundColor: "#f0f5f7",
    flexWrap: "wrap",
    '@media (max-width: 1127px)': {
      justifyContent: "center"
    },
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "12px",
    '@media (max-width: 804px)': {
      justifyContent: "center",
      width: "100%"
    },
  },

  SubDivC: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",
  },

  filterStatusText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Poppins",
    padding: "3px 6px",
    height: "20px",
    borderRadius: "30px",
    MarginTop: "20px"
  },
  totalExpense: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 0,
    alignContent: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#0F172A'
  },
  createIcon: {
    marginRight: "10px",
    width: "24px",
    height: "24px"
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px"
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "0px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "#FFFFFF",
    margin: "10px 30px"
  },
   
  addTaskPopupHeadings: {
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#475569',
  }
};

const StyledCalendar = styled(Calendar)({
  "&.react-calendar": {
    border: "none"
  },
  "& .react-calendar__navigation": {
    alignItems: "center",
    marginBottom: "0px"
  },
  "& .react-calendar__navigation__prev2-button": {
    display: "none"
  },
  "& .react-calendar__navigation__next2-button": {
    display: "none !important"
  },
  "& .react-calendar__navigation__label": {
    display: "flex",
    flex: "unset !important",
    justifyContent: "space-between",
    margin: "auto",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#0F172A"
  },
  "& .react-calendar__navigation__label__divider": {
    display: "none"
  },
  "& .react-calendar__viewContainer": {
    margin: "0px"
  },
  "& .react-calendar__month-view__weekdays": {
    textTransform: "none",
    color: "#64748B"
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: "none"
  },
  "& .react-calendar__tile--active:enabled:hover": {
    background: "#E0EDF0",
  },
  "& .react-calendar__tile--now": {
    background: "transparent"
  },
  "& .react-calendar__tile--active": {
    background: "#E0EDF0",
    borderRadius: "50%",
    border: "5px solid #fff"
  },
  "& .react-calendar__tile": {
    color: "#0F172A"
  },
  "& .custom-calendar .disabled-date": {
    color: "gray", 
    pointerEvents: "none",
    opacity: "0.5"
  },
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .react-calendar__navigation__prev-button": {
    fontSize: '24px',
    color: "#94A3B8",
    backgroundColor: 'transparent',
    border: 'none',
  },
  "& .react-calendar__navigation__next-button": {
    fontSize: '24px',
    color: "#94A3B8",
    backgroundColor: 'transparent',
    border: 'none',
  }


});

 const StyledCheckbox = styled('input')({
   width: "20px",
   height: "20px",
   borderRadius: "6px",
   border: "1px solid #64748B",
   cursor: "pointer",
   position: "relative",
   appearance: "none",
   outline: "none",
   backgroundColor: "#fff",
   marginRight: "10px",
   marginLeft: "0px",
   "&:checked": {
    backgroundColor: "#325962",
    border: "none"
  },
  "&:checked::after": {
    content: '""',
    position: "absolute",
    top: "2px",
    left: "7px",
    width: "5px",
    height: "10px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    transform: "rotate(45deg)"
  }
});

const AddTaskDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '35px 24px',
    boxShadow: 'none'
  },
  '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  }
});
const CreateInvoiceDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '24px',
    boxShadow: 'none'
  },
  '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginTop: '4px',
    color: '#94A3B8',
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  },
  "& .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: '8px'
  },
  "& .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
    borderRadius: '8px'
  }

});

const FilterButton = styled(Button)({
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  color: "#0F172A",
  fontWeight: 400,
  fontSize: "16px",
  height: "32px",
  width: "90px",
  letterSpacing: 0,
  lineHeight: "22px",
  textTransform: "none",
  padding: "10px 10px 10px 10px",
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Poppins",
  cursor: "pointer",
});

const MeasurementUnitField = styled(Box)({
  '& .MuiOutlinedInput-root': {
    paddingRight: '10px',
    '& fieldset': {
      borderColor: '#D3E4E9',
    },
    '&:hover fieldset': {
      borderColor: ' #D3E4E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#325962',
    },
  }
});

const CalendarStyle = styled(Menu)({
  "& .react-calendar": {
    border: "none",
    fontFamily: "Poppins",
    width: "348px",
    boxShadow: 'none'
  },
  "& .css-6hp17o-MuiList-root-MuiMenu-list": {
    width: '348px',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: 'none'
  }
  ,
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    width: '24px',
    height: '24px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0F172A',
    fontSize: '14px',
    fontFamily: "Poppins",
    fontWeight: 600,
    padding: '12px'
  },
  "& .react-calendar__navigation": {
    display: 'flex',
    gap: '10px'
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button": {
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '30px',
    color: "#94A3B8",
    backgroundColor: 'transparent',
    border: 'none',
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    fontSize: "14px",
    color: '#0F172A',
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    fontFamily: "Poppins",
    textDecoration: 'none',
    backgroundColor: 'transparent'
  },
  '& .react-calendar__month-view__weekdays': {
    padding: '8px'
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    fontFamily: "Poppins",
    width: '50px',
    height: '50px',
    backgroundColor: 'transparent',
    border: 'none'
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#E0EDF0',
    color: '#325962',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '100% 0 0 100%'

  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '0 100% 100% 0'
  },
  "& .react-calendar__tile--active.react-calendar__tile--rangeStart": {
    background: '#ABCDD5',
    color: '#FFFFFF',
    borderRadius: '100% 0 0 100%'
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
  },
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .custom-calendar": {
    "& .react-calendar__tile--disabled": {
      backgroundColor: "#F1F5F9",
      color: "#94A3B8",
      pointerEvents: "none",
      cursor: "not-allowed"
    },
    "& .react-calendar__tile--disabled:hover": {
      backgroundColor: "#F1F5F9"
    },
  },
  "& .custom-calendar .disabled-date": {
    color: "gray",
    pointerEvents: "none",
    opacity: "0.5"
  }
});

const FilterDialog = styled(Dialog)({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '& .applyBtn': {
    fontWeight: 600,
    fontSize: "16px",
    color: "#fff",
    background: '#237182',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 20px',
    lineHeight: "24px"
  },
  "& .clearButton": {
    color: "#237182",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Poppins",
    background: '#fff',
    border: 'none',
    lineHeight: "26px"
  },
  '& .setApplyBtn': {
    padding: '20px',
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  '& .innerFilter': {
    gap: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  '& .mainFilter': {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    display: 'flex',
  },
  '& .filterText': {
    fontFamily: 'poppins',
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#0F172A"
  },
  '& .clearText': {
    fontFamily: 'poppins',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#527FE2',
    fontWeight: 400,
  },
  '& .MuiDialog-container': {
    right: '0',
    position: 'absolute',
    '& .MuiPaper-root': {
      margin: '0',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      width: "576px",
      '@media (max-width: 570px)': {
        width: '350px',
      },
      '@media (max-width: 370px)': {
        width: '300px',
      },
    },
  },
  '& .MuiAccordion-root': {
    boxShadow: 'none',
    border: 'none',
  },
  '& .MuiAccordionSummary-root': {
    borderBottom: 'none',
  },
  '& .MuiAccordionDetails-root': {
    padding: '8px 16px', 
  },
});
const AccordionBox = styled(Box)({
  width: '100%',
  "& .accordianBorder": {
    "&.MuiAccordion-root::before": {
      backgroundColor: "transparent"
    }
  },
  '& .accText': {
    fontWeight: 600,
    color: "#0F172A",
    fontSize: "14px",
    lineHeight: "22px"
  },
  '& .MuiPaper-root': {
    '& .MuiButtonBase-root': {
      padding: '0px 20px !important',
      '& .MuiIconButton-label': {
        color: 'black'
      }
    },
    '& .MuiCollapse-root': {
      background: '#F8FAFC',
      '& .MuiCollapse-wrapper': {
        '& .MuiCollapse-wrapperInner': {
          '& .MuiAccordionDetails-root': {
            padding: '0px 28px',
            background: '#F8FAFC',
            '& .MuiFormControl-root': {
              marginTop: '0px',
              marginBottom: '0px'
            }
          }
        }
      }
    }
  }
});

const CustomToast = styled(ToastContainer)({
  width: "40vw",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    fontSize : "16px",
    fontWeight : 400,
    padding: 0,
  },
});
// Customizable Area End
