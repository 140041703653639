// Customizable Area Start
import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton, MenuItem,
  Box,Typography,Button,InputAdornment, Grid, Tabs,Divider, Tab, Switch, TextField, Menu, AppBar, Toolbar,
  DialogActions,
  Dialog,
  Chip,
  Modal,
  Checkbox,
  

} from "@material-ui/core";
import { withStyles, Theme, createStyles, createTheme } from '@material-ui/core/styles';
import { styled, ThemeProvider, } from "@material-ui/styles";
import Slider from '@mui/material/Slider';
import {Close,Search,MoreVert, EditSharp, ControlPointDuplicateSharp, DeleteOutline, Clear,DateRange,PlaceOutlined,PersonOutlineOutlined,ControlPoint, KeyboardArrowDown,KeyboardArrowUp,Check} from '@material-ui/icons';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import MessageBox from "../../../components/src/messageBox";
import ProjectPortfolioController, {
  Props
} from "./ProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { addProjectFrame,frameIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },},});
 const styleSheet = 
{logoutButton: {background: "#237182",color: "#FFFFFF",fontSize: "16px",fontWeight:600,
   fontFamily: "Poppins",textTransform: "none",height: "56px",borderRadius: "8px",width: "180px",
   padding: "10px 16px 10px 16px",} as const,
 logoutButton2: 
 {background: "#237182",color: "#FFFFFF",fontSize: "16px",fontFamily: "Poppins",
   textTransform: "none",height: "56px",borderRadius: "8px",width: "121px",
   padding: "10px 16px 10px 16px",fontWeight:600,} as const,
 cancelButton: 
 {backgroundColor: "#E2E8F0",color: "#325962",fontSize: "16px",fontFamily: "Poppins",
   textTransform: "none",height: "56px",borderRadius: "8px",width: "96px",fontWeight:600,} as const,
 title: 
 {
   fontWeight: 700,fontSize: "24px",color: "#0F172A",fontFamily: "Poppins",
   lineHeight: "32px",letterSpacing: "-0.005em"},
  cursorStyle: {cursor: 'pointer'}
  }

export default class ProjectPortfolio extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }
  getBorderColor = (activeTab: number) => {
    switch (activeTab) {
        case 0:
            return '#3B82F6';
        case 1:
            return '#F59E0B';
        case 2:
            return '#475569';
        case 3:
            return '#10B981';
        default:
            return '#EF4444';
    }
};
  getTabStyle = (isActiveTab: boolean, index: number): React.CSSProperties => {
    const borderRadius = index === 4 ? "8px" : "25px";
    return {
      minWidth: "auto",
      paddingInline: "20px",
      textTransform: "none",
      borderRadius,
      backgroundColor: isActiveTab ? "#E0EDF0" : "#F8FAFC",
      color: isActiveTab ? "#0F172A" : "#64748B",
      fontWeight: isActiveTab ? "bold" : 600,
      fontFamily: "Poppins",
    };
  };

  ProjectStatus = ({ project }:any) => {
    const statusMap:any = {
        Ongoing: { label: "Ongoing", color: '#3B82F6', backgroundColor: '#DBEAFE' },
        Draft: { label: "Draft", color: '#64748B', backgroundColor: '#F1F5F9' },
        Completed: { label: "Completed", color: '#ECFDF5', backgroundColor: '#10B981' },
        Paused: { label: "Paused", color: '#EF4444', backgroundColor: '#FEE2E2' },
        Proposed: { label: "Proposed", color: '#10B981', backgroundColor: '#D1FAE5' }
    };

    const renderProjectStatus = () => {
        const statusKey:any = project; 
        const status = statusMap[statusKey];
    return status ? (
            <Chip 
                style={{ color: status.color, backgroundColor: status.backgroundColor, fontFamily:'Poppins', fontWeight:400, fontSize:'12px' }} 
                label={status.label} 
            />
    ) : null;
};

    return (
        <div>
            {renderProjectStatus()}
        </div>
    );
};

filterProjectsByStatus = (projects: any[], activeTab: number | null, selectedStatuses: string[]) => {
  const statusMapping: { [key: number]: string | string[] } = this.state.role === "client" ? {
    0: 'Ongoing',
    1: 'Completed',
    2: 'Paused',
  } : 
  {
    0: 'Ongoing',
    1: ['Pending', 'Accepted', 'Rejected'],
    2: 'Draft',
    3: 'Completed',
    4: 'Paused',
  }
  ;

  return projects.filter((project) => {
    let tabFilter = true;
    if (activeTab !== null && statusMapping[activeTab]) {
      const statusList = statusMapping[activeTab];
      tabFilter = Array.isArray(statusList)
        ? statusList.includes(project.status)
        : project.status === statusList;
    }

    let checkboxFilter = true;
    if (selectedStatuses.length > 0) {
      checkboxFilter = selectedStatuses.includes(project.status);
    }

    return tabFilter && checkboxFilter;
  });
};




sortProjects = (projects: any, sortType: any) => {


return projects.sort((a: any, b: any) => {
   
  const currentDate = new Date().getTime(); 
  const startDateA = new Date(a.end_date).getTime();
  const startDateB = new Date(b.end_date).getTime();
      if (sortType === 'date_asc') {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      }else if (sortType === 'A_Z') {
        return a.title?.localeCompare(b.title); 
      } else if (sortType === 'Z_A') {
        return b.title?.localeCompare(a.title); 
      }else if (sortType === 'Most_Completed') {
        return b.completion_percentage - a.completion_percentage;
      } else if (sortType === 'Least_Completed') {
      return a.completion_percentage - b.completion_percentage;
    }  else if (sortType === "Closest_First") {
      return Math.abs(startDateA - currentDate) - Math.abs(startDateB - currentDate);
    } else if (sortType === "Furthest_First") {
      return Math.abs(startDateB - currentDate) - Math.abs(startDateA - currentDate);
    } 

    return 0;
  });
};

handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ searchText: event.target.value });
};




getFilteredAndSortedProjects = () => {
  const { projects, activeTab, sortTypeValue, searchText, selectedStatuses } = this.state;
  const filteredByStatus = this.filterProjectsByStatus(projects, activeTab, selectedStatuses);
  const filteredBySearch = filteredByStatus.filter(
    (project) =>
      project.title &&
      project.title.toLowerCase().includes(searchText.toLowerCase())
  );
  return this.sortProjects(filteredBySearch, sortTypeValue);
};

  navigatingBasedOnStatus = (project: {id: string, status: string, draft: boolean, files_uploaded: boolean,project_planid:string, step:any }) => {    
    switch (project.status){
      case 'Draft':
        this.handleOpenDraft(project.id, project.draft, project.files_uploaded,project.project_planid,project.step)
        break
        case 'Ongoing':
        case 'Completed': 
        case 'Paused':
        this.navigateToProjectDashboard(project.id);
        break
        case 'Pending':
          this.navigateToProjectProposalgeneration(project.id);
          break
          case 'Rejected':
            this.navigateToProjectProposalgeneration(project.id);
            break
            case 'Accepted':
              this.navigateToProjectProposalgeneration(project.id);
              break
      default:
        break
    }
  }
   getStatusBackgroundColor = (status: string) => {
    switch (status) {
      case "Rejected":
        return "#FEE2E2";
      case "Accepted":
        return "#D1FAE5";
      case "Pending":
        return "#FEF3C7";
      default:
        return "transparent";
    }
  };
   getStatusTextColor = (status: string) => {
    switch (status) {
      case "Rejected":
        return "#DC2626";
      case "Accepted":
        return "#059669";
      case "Pending":
        return "#D97706";
      default:
        return "transparent";
    }
  };
  render() {

    const sortedAndFilteredProjects = this.getFilteredAndSortedProjects();   
    const tabs = this.state.role === "client" ?
      [
        { label: "Ongoing", index: 0 },
        { label: "Completed", index: 1 },
        { label: "Paused", index: 2 },
      ] :
      [
        { label: "Ongoing", index: 0 },
        { label: "Proposed", index: 1 },
        { label: "Draft", index: 2 },
        { label: "Completed", index: 3 },
        { label: "Paused", index: 4 },
      ]

    
    const renderTab = ({ label, index }: { label: string; index: number }) => {
      const isActiveTab = this.state.activeTab === index;
      const tabStyle = this.getTabStyle(isActiveTab, index);
    
      return <Tab key={label} label={label} style={tabStyle} />;
    };
 

    

    return (
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.loading} />

        <Box sx={portfolioStyles.mainCont}>
        {this.state.messageToggle && <MessageBox data-test-id="toastMessage" message={this.state.tostifymessage} status={this.state.status}/>}
        <MyProfileHeader navigation={this.props.navigation} handleDialogOpen={this.handleOpenDialogLeave}  tabName={'Projects'}>
        {this.state.openDialogLeave && <Dialog PaperProps={{
                      style: {
                        width: '584px',
                        height: '245px',
                        borderRadius: "16px",
                        padding: "40px 32px 40px 32px",
                        color: "#FFFFFF"
                      }
                    }} open={this.state.openDialogLeave} onClose={this.handleCloseDialog}>
                      <Box >
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >Are you sure you want to duplicate this project?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px",
                          marginRight:"15px",
                          marginTop: "5px"
                        }}><Clear /></Typography>
                      
                        </Box>
                        <Box sx={{marginTop:'16px',paddingRight:'50px'}}>
                        <Typography style={{
                           color : '#1E293B',
                           fontFamily: 'Poppins',
                           fontSize: '16px',
                           fontWeight: 400,
                           lineHeight: '24px',
                        }} >All tasks within this project will be duplicated, including their details and associated files.</Typography> 
                        </Box>
                        <DialogActions style={{
                          marginTop: "45px",
                          gap: "10px"
                        }}>
                          <Button style={styleSheet.cancelButton} onClick={this.handleCloseDialog}>
                            Cancel
                          </Button>
                          <Button data-test-id="handleDuplicateProject"  style={styleSheet.logoutButton}  onClick={this.state.handleDuplicateProject ? this.state.handleDuplicateProject : undefined}>
                            Duplicate Project
                          </Button></DialogActions>

                      </Box>
         </Dialog>}
            <CustomTabs position="static" elevation={0}>
                <Toolbar
                    style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        width: "94%",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        marginTop: "24px",
                        borderRadius: "8px",
                    }}
                >
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{backgroundColor: "#F8FAFC", borderRadius: "18px", marginRight: "10px"}}
                    >
                        {tabs.map(renderTab)}
                    </Tabs>
                    <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} />
                    <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center', }}>

                     <Box  style={{ display: 'flex', alignItems: 'center', }}>
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 400, paddingInline: '4px', fontFamily: 'Poppins' }}>Sort By:</Typography>
                        <Typography style={{
                                        color: "#237182",
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        cursor:"pointer",
                                    }}
                                        data-test-id="openSortDropdown"
                                        onClick={this.openSortDropdown.bind(this)}
                                        >{this.state.sortTypeText}</Typography>
                                        <Box onClick={this.openSortDropdown.bind(this)} >
                                        {this.state.menuAnchorSort ? (
                                          <KeyboardArrowUp
                                            style={{ color: "#237182" }}
                                            data-test-id="openSortDropdown"
                                          />
                                        ) : (
                                          <KeyboardArrowDown
                                            style={{ color: "#237182" }}
                                            data-test-id="openSortDropdown"
                                          />
                                        )}
                                        </Box>
                                        <MenuDropdown
                                        anchorEl={this.state.menuAnchorSort}
                                        open={Boolean(this.state.menuAnchorSort)}
                                        data-test-id='handleMenuClose'
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        PaperProps={{
                                            style: {
                                                marginTop: "46px",
                                                padding: '1px 4px',
                                                borderRadius: '8px',
                                                width: '184px'
                                            },
                                        }}
                                    >
                                           <Typography style={{ color: "#64748B", fontSize: '12px', fontWeight: 400, padding: "7px 17px 1px 15px",
                                            fontFamily: 'Poppins', textTransform:"uppercase" }}>Alphabetical</Typography>
                                          <MenuItem data-test-id="handleSortByChange"  onClick={this.handleSortByChange.bind(this,  "A_Z",  "Alphabetical A To Z")}  style={{ ...webStyles.downloadText, marginLeft: 'unset' }}>
                                               A to Z
                                            </MenuItem>
                                            <MenuItem data-test-id="handleSortByChange"  onClick={this.handleSortByChange.bind(this,  "Z_A",  "Alphabetical Z To A")}  style={{ ...webStyles.downloadText, marginLeft: 'unset' }}>
                                               Z to A
                                            </MenuItem>
                                            <Box style={{
                                               display: this.state.activeTab === 1 || this.state.activeTab === 2 ? "none" : "block",
                                                 }}>
                                            <Typography style={{ color: "#64748B", fontSize: '12px', fontWeight: 400, padding: "7px 17px 1px 15px",
                                            fontFamily: 'Poppins',textTransform:"uppercase" }}>Completion Status</Typography>
                                            <MenuItem data-test-id="handleSortByChange"  onClick={this.handleSortByChange.bind(this,  "Most_Completed",  "Most Completed")}  style={{ ...webStyles.downloadText, marginLeft: 'unset' }}>
                                              Most Completed
                                            </MenuItem>
                                            <MenuItem data-test-id="handleSortByChange"  onClick={this.handleSortByChange.bind(this,  "Least_Completed",  "Least Completed")}  style={{ ...webStyles.downloadText, marginLeft: 'unset' }}>
                                              Least Completed
                                            </MenuItem>
                                            </Box>
                                            <Typography style={{ color: "#64748B", fontSize: '12px', fontWeight: 400, padding: "7px 17px 1px 15px",
                                            fontFamily: 'Poppins',textTransform:"uppercase" }}>Deadline</Typography>
                                            <MenuItem data-test-id="handleSortByChange"  onClick={this.handleSortByChange.bind(this,  "Closest_First",  "Closest First")}  style={{ ...webStyles.downloadText, marginLeft: 'unset' }}>
                                            Closest First
                                            </MenuItem>
                                            <MenuItem data-test-id="handleSortByChange"  onClick={this.handleSortByChange.bind(this,  "Furthest_First",  "Furthest First")}  style={{ ...webStyles.downloadText, marginLeft: 'unset' }}>
                                            Furthest First
                                            </MenuItem>
                                    </MenuDropdown>
                       
                     </Box> 
                     <Box flexGrow={1} style={{ display: this.state.activeTab === 1 ? "flex" : "none",
                       alignItems: "center" ,marginLeft:"20px" }}>
                        <Typography
                    style={{
                      color: "#237182",
                      fontSize: "16px",
                      fontWeight: 600,
                      cursor: "pointer",
                      alignItems: "center",
                      display: "flex",
                      gap: "10px",
                    }}
                    data-test-id="openSortDropdown"
                    onClick={this.openSortstatus}
                  >
                    Status{" "}
                    {this.state.selectedStatuses.length > 0 && (
                      <span>
                        {this.state.selectedStatuses.length < 3
                          ? <span style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "rgb(35, 113, 130)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color:"white"

                          }}>{this.state.selectedStatuses.length}</span>
                          : "All"}
                      </span>
                    )}
                  </Typography>
                              <Box onClick={this.openSortstatus}>
                        {this.state.menuAnchorSortStatus ? (
                          <KeyboardArrowUp
                            style={{ color: "#237182" }}
                            data-test-id="openSortDropdown"
                          />
                        ) : (
                          <KeyboardArrowDown
                            style={{ color: "#237182" }}
                            data-test-id="openSortDropdown"
                          />
                        )}
                      </Box>
                    <MenuDropdown
                      anchorEl={this.state.menuAnchorSortStatus}
                      open={Boolean(this.state.menuAnchorSortStatus)}
                      data-test-id="handleMenuClose"
                      onClose={this.handleMenuClosestatus}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      PaperProps={{
                        style: {
                          marginTop: "46px",
                          padding: "1px 4px",
                          borderRadius: "8px",
                          width: "184px",
                        },
                      }}
                    >
                      {["Accepted", "Pending", "Rejected"].map((status) => (
                        <MenuItem
                          key={status}
                          data-test-id="handleSortByChange"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={this.state.selectedStatuses.includes(status)}
                            onClick={this.handleSortByChangeStatus.bind(this, status)}
                            style={{
                              padding: 0,
                              borderRadius: "6px",
                              width: "20px",
                              height: "18px",
                              pointerEvents: "auto",
                              cursor: "pointer",
                            }}
                            icon={
                              <span
                                style={{
                                  backgroundColor: "#FFFFFF",
                                  borderRadius: "6px",
                                  width: "20px",
                                  height: "18px",
                                  border: "1px solid #64748B",
                                  display: "inline-block",
                                  cursor: "pointer",
                                }}
                              />
                            }
                            checkedIcon={
                              <span
                                style={{
                                  backgroundColor: "#325962",
                                  color: "#fff",
                                  borderRadius: "6px",
                                  width: "20px",
                                  height: "18px",
                                  display: "inline-block",
                                  border: "1px solid #325962",
                                }}
                              >
                                <Check style={{ color: "white", fontSize: 18 }} />
                              </span>
                            }
                          />
                          <Typography
                            style={{
                              color: "#0F172A",
                              padding: "0px 17px 0px 15px",
                              fontFamily: "Poppins",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "22px",
                              letterSpacing: "0px",
                            }}
                          >
                            {status}
                          </Typography>
                        </MenuItem>
                      ))}
                      <Box style={{textAlign:"center"}}>

                      <Box  style={{textTransform: 'none', boxShadow: 'none',cursor:"pointer"}}
                      onClick={this.handleClearAll} 
                      >
                          <Typography
                            style={{
                              color: "#237182",
                              fontSize: "16px",
                              fontWeight: 600,
                              padding: "10px 17px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              cursor:"pointer"
                            }}
                          >
                            Clear All
                          </Typography>
                    </Box>
                      </Box>
                    </MenuDropdown>
                    </Box>
                    </Box>
            
        <Box style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
        <CustomSearchBox
        data-test-id="searchInputText"
  variant="outlined"
  placeholder="Search here..."
  value={this.state.searchText}
  onChange={(e:any) => this.handleSearchChange(e)}
  error={!!(this.getFilteredAndSortedProjects().length === 0 && !this.state.loading && this.state.searchText)}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <Search style={{ color: '#94A3B8' }} />
      </InputAdornment>
    ),
    endAdornment: (
      this.state.searchText && (
        <InputAdornment style={{marginRight:'-10px'}} position="end">
          <IconButton onClick={() => this.setState({ searchText: '' })}>
            <Clear />
          </IconButton>
        </InputAdornment>
      )
    ),
  }}
/>
                  {this.state.role !== 'client' && (
                    <CreateButton data-test-id="handleCreateNew"
                      onClick={this.handleNavigation.bind(this)}
                    >
                      <ControlPoint style={{ paddingRight: "12px" }} />
                      Create Project
                    </CreateButton>
                  )}
{ this.getFilteredAndSortedProjects().length === 0 && !this.state.loading && this.state.searchText && <Box style={{backgroundColor:'#FCE8E5',borderRadius:'24px',padding:'5px',height:'20px',position:'absolute',marginTop:'80px', color:'#DC2626', fontFamily:'Poppins', fontWeight:'600px', fontSize:'12px'}}>No project exists with this name</Box>}
</Box>
                </Toolbar>
            </CustomTabs>
           
            {this.state.projects.length>0 &&
            <Grid container spacing={2} style={{ padding: '28px 32px 15px 32px' }}>
{
  sortedAndFilteredProjects.map((project:any, index:any) => (
    
        <Grid item xs={12} sm={6} md={4} lg={3} key={project.id} style={{ display: 'flex', height: 'fit-content' }}>
          
            <Card elevation={0} style={{ boxShadow: "0px 4px 6px #00000014", display: 'flex', width: '100%', padding: "1rem", borderRadius: "8px",cursor:"pointer",
              borderTop: `4px solid ${this.getBorderColor(this.state.activeTab)}` }} data-test-id={`projectDetail${index}`}>
                <CardContent style={{
                    display: "flex",
                    flexDirection: "column",
                    width: '100%',
                    padding: 0,
                }}
                >
                  <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                  }}
                  >

                    {this.state.isEditing[project.id] ? (
                        <TextField
                            value={this.state.tempName[project.id] || ''}
                            onChange={(e:any) => this.handleInputChange(project.id, e)}
                            onBlur={() => this.handleBlur(project.id)}
                            error={!!this.state.errors[project.id]}
                            helperText={this.state.errors[project.id]}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => this.handleKeyDown(project.id, event)} 
                            autoFocus
                            data-test-id="rename-field"
                        />
                    ) : (
                      <TextTruncate onClick={this.navigatingBasedOnStatus.bind(this, project)}>
                     { project.title}
                  </TextTruncate>
                   
                    )}
                    {this.state.role !== 'client' && (
                    <MoreOption onClick={this.toggleProjectMenu.bind(this, project.id)} style={{padding:"0px"}}>
                      <MoreVert style={{width:'28px',height:'28px',color:'#475569'}} />
                        </MoreOption>
                    )}
                  </Box>
                    <div
              onClick={this.navigatingBasedOnStatus.bind(this, project)}
                    >
                    <Box style={{
                    display: this.state.activeTab === 1 || this.state.activeTab === 2 ? "none" : "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
            <Slider
                aria-label="Temperature"
                defaultValue={project.completion_percentage}
                valueLabelDisplay="on" // This will show the value label
                disabled // Make the slider read-only
                sx={{
                    // color: '#237182', // Custom color for the slider
                    '& .MuiSlider-thumb': {
                        display: 'none', // Hide the thumb
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: '#237182', // Optional: Set track color
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#D3E4E9', // Optional: Set rail color
                    },
                }}
            />
               {<Typography style={{...webStyles.subText,padding:"10px", paddingRight:"0", fontSize:"16px", fontWeight:"600" ,marginBottom:"0"}}>
               <span>{project.completion_percentage}%</span></Typography>}
        </Box>
        
                   <Box style={{...webStyles.displayGap, display:"flex", justifyContent:"space-between",alignItems: "center",}}>
                    <Box style={webStyles.displayGap}>
                       <CurrencyPoundIcon style={webStyles.icons} />
                      <Typography style={webStyles.subText}>
                           <span>{project.total_cost}</span>
                    </Typography>
                    </Box>
                                          <Typography
                        style={{
                          ...webStyles.subText,
                          display: this.state.activeTab === 1 ? "block" : "none",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: this.getStatusBackgroundColor(project.status),
                            color: this.getStatusTextColor(project.status),
                            padding: "2px 8px",
                            borderRadius: "20px",
                            fontWeight: "700", 
                            fontSize:"12px",
                            marginTop: "6px",
                            marginRight: "9px",
                            textTransform:"uppercase",
                          }}
                        >
                          {project.status}
                        </span>
                      </Typography>

                  </Box>
                    <Box style={webStyles.displayGap}>
                      <DateRange style={webStyles.icons} />
                         {<Typography style={webStyles.subText}>
                         <span>{project.start_date} - {project.end_date}</span></Typography>}
                          </Box>
                
                        <Box style={webStyles.displayGap}>
                                                <PlaceOutlined style={webStyles.icons} />
                                                <TextTruncate style={webStyles.subText}>{project.location}, {project.country_name}</TextTruncate>
                                            </Box>
                                            <Box style={webStyles.displayGap}>
                                                <PersonOutlineOutlined style={webStyles.icons} />
                                                <TextTruncate style={webStyles.subText}>
                                                  {this.state.role === "client" ? project.company_name : `${project.firstName} ${project.lastName}`}
                                                </TextTruncate>
                                            </Box>
                    </div>
                </CardContent>
                {this.state.role !== 'client' && (
                <CardActions disableSpacing style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 0,
                    alignItems: "flex-end",
                }}>
                    {this.state.selectedProjectId === project.id && (
                        <div className="menu" style={{ position: 'absolute', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', padding: '8px', zIndex: 1000 }}>
                            <MenuItem 
                               data-test-id="handleRenameProject"
                               onClick={(e) => {
                                   e.stopPropagation();
                                   this.handleRenameProject(project.id);
                                   this.setState({ selectedProjectId: null });
                               }}
                               style={{ borderTop: "1px solid #E2E8F0" }}
                            >
                                <EditSharp style={{ height: '18px', marginRight: '4px' }} />
                                Rename
                            </MenuItem>
                            <MenuItem 
                                data-test-id="openDuplicateDialog"
                                onClick={() => this.openDuplicateDialog(project.id)}
                            >
                                <ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />
                                Duplicate
                            </MenuItem>
                            <MenuItem
                                data-test-id="handleDeleteProject"
                                onClick={() => {this.Openconfirmmodel(project.id) }}
                                style={{ color: '#DC2626', alignItems: 'flex-start' }}
                            >
                                <DeleteOutline style={{ height: '20px', marginRight: '4px' }} />
                                Delete
                            </MenuItem>
                        </div>
                    )}
                   
                </CardActions>
                )}
            </Card>
        </Grid>
    ))

}



                </Grid>}
                {(this.state.projects.length === 0 || sortedAndFilteredProjects.length === 0) &&
                ( <Grid container spacing={2} style={{ padding: '15px', backgroundColor: '#F0F5F7',display:'contents' }}>
                  {this.state.searchText.length > 0 && sortedAndFilteredProjects.length === 0 ? 
               <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
                <Box>
                  <img src={frameIcon} alt=""/>
                </Box>
                <Box>
                  <Typography style={{    fontFamily: 'Poppins',
                  color:'#0F172A',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '-0.005em',
                  textAlign: 'center',}}>No Results Found</Typography>
                </Box>
                <Box style={{width: "323px",}}>
                <Typography style={{   
                  marginTop:'16px',
                  color:'#0F172A',
                   fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    textAlign: 'center',}}>We couldn't find that you searched for. Try searching again.</Typography>
                </Box>
                <Box>
                <Button
                    data-test-id="handleCreateNewProjectsearched"
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop:'16px',
                      backgroundColor: "#237182",
                      color: "white",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "10px 16px",
                      textTransform: "capitalize",
                      display:"none",
                    }}
                  >
                    Create new project
                  </Button>
                
                </Box>
               </Box>
               : 
                <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
                <Box>
                  <img src={addProjectFrame} alt=""/>
                </Box>
                <Box>
                  <Typography style={{    fontFamily: 'Poppins',
                  color:'#0F172A',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '32px',
                  letterSpacing: '-0.005em',
                  textAlign: 'center',}}>No Projects Here</Typography>
                </Box>
                {this.state.role !== "client" && (
                  <>  
                <Box>
                <Typography style={{   
                  marginTop:'16px',
                  color:'#0F172A',
                   fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'center',}}>Click 'Create Project' to get started.</Typography>
                </Box>
                <Box>
                <Button
                    data-test-id="handleCreateNewProject"
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop:'16px',
                      backgroundColor: "#237182",
                      color: "white",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "10px 16px",
                      textTransform: "capitalize",
                      display:"none",
                    }}
                  >
                    Create new project
                  </Button>
                
                </Box>
                  </>
                  )}
               </Box>
               }
            </Grid>)
            }
    </MyProfileHeader>
           
        </Box>
        <Modal open={this.state.DeleteModalOpen} style={{ display: "flex" }}>
        <Box style={webStyles.renameModal}>
          <Box style={webStyles.modalHeader}>
            <Typography style={webStyles.renameText}>
            Are you sure you want to delete this project?
            </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.DeleteModelclose.bind(this)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography  style={webStyles.leave_chat_sub_heading}>This action cannot be undone.</Typography>
          <Box style={{textAlign:"end", marginTop:"24px"}}>
          <RenameButton
            data-test-id="create-id"
            style={{
              padding: "1rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#E0EDF0",
              color: "#325962",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.DeleteModelclose}
          >
            Cancel
          </RenameButton> 
          <RenameButton
            style={{ 
              marginLeft: "8px" ,
              padding: "1rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#237182",
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.DeleteConfirm.bind(this)}
            data-test-id="leavechatConfirm"

          >
           Delete
          </RenameButton> 
          </Box>
          
        </Box>
      </Modal>
        </ThemeProvider>
    );
}

}

const CustomMenu = styled(Menu)({
  "& .MuiPaper-root": {
    border: "1px solid #E2E8F0",
    width:"190px",
    height: "121px",  
    alignItems: "center",
    display: "grid",
    borderRadius: "8px",
  },
  "& .MuiList-padding": {
    paddingTop:0,
    paddingBottom:0
  },
  "& .MuiListItem-root": {
    margin: "0 4px",
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "#E0EDF0",
    borderRadius: "8px",
  }
});

const CustomSearchBox = styled((TextField))({
  "& .MuiOutlinedInput-root": {
    borderRadius:'8px',
    height:'44px',
    [theme.breakpoints.down('md')]:{
     width:'200px'
    },
    [theme.breakpoints.down(1450)]:{
      width:'300px'
     },
     [theme.breakpoints.up(1450)]:{
      width:'300px'
     },
    "& fieldset": {
      borderColor: '#CBD5E1',
    },
    "&:hover fieldset": {
      borderColor: '#CBD5E1',
    },
    "&.Mui-focused fieldset": {
      borderColor: '#CBD5E1',
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 0px",
    fontWeight:400,
    fontSize:'16px',
    fontFamily:'Poppins',
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    }
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(10px, 10px)"
  },
  "& .MuiFormLabel-root": {
    fontSize: '15px',
    color: '#94A3B8'
  }
});

const portfolioStyles= {
  mainCont: {bgcolor: '#F0F5F7', minHeight: '100%'}
}

const CustomTabs = styled((AppBar))({
  width: "100%",
  backgroundColor: "#F0F5F7",
  "& .MuiTab-root":{
    minHeight:'auto'
  },
  "& .MuiTabs-root":{
    minHeight:'auto'
  }
})

const CustomDropDown = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#fff",
    color: "#237182",
  },
  "& .MuiSelect-select": {
    paddingBlock: "10px",
    color: "#237182",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .MuiSvgIcon-root": {
    color: "#237182",
  },
});


const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#325962',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #ffffff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: 'grey',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
  
)(Switch);
const webStyles = {
  downloadText: {
    color: '#0F172A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: '8px'
},
  subText: {
    color: '#64748B',
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "8px",
    letterSpacing: "0px",
},
  displayGap: {
    display: 'flex',
    gap: '4px',
    marginTop: '8px'
},
icons: {
  color: '#64748B',
  width: '20px',
  height: '20px'
},
  renameModal: {
    margin: "auto",
    height: "fit-content",
    padding: "2.5rem 2rem",
    width: "40vw",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  leave_chat_sub_heading:{
    fontFamily: "poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color:"#1E293B",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameText: {
    fontFamily: "poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color:"#0F172A"
  },
}
const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,

  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },

});
const ProjectCard = styled(Box)({
  background: '#fff',
  borderRadius: '8px',
  padding: '16px',
  boxShadow: '0px 2px 8px 0px #00000014',
  position: 'relative',
  display:"flex",
  cursor:"pointer"
});
const ProjectOption = styled(Box)({
  '& .MuiList-padding': {
      paddingTop: '3px',
      paddingBottom: '3px'
  },
  '& .MuiPaper-elevation8': {
      boxShadow: 'unset',
      border: '1px solid #E0EDF0'
  },
  "& .MuiButtonBase-root":{
    borderRadius:"8px",
    padding: "8px 16px",
    "&:hover":{
      background:"#E0EDF0",
      borderRadius:"8px",
      padding: "8px 16px",

    }
  }
})
const TextTruncate = styled(Typography)({
  color: '#0F172A',
  fontSize: '16px',
  fontWeight: 600,
  fontFamliy: 'Poppins',
  textAlign: 'left' as 'left',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1, // Limits text to 2 lines
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word', // Ensures words don't overflow
  maxHeight: '3rem',
})
const MoreOption = styled(Box)({
  cursor:'pointer',
  padding:"3px",
  "&:hover": {
    background: "#D3E4E9",
    borderRadius: "50%",
  
  },
  })
  const CreateButton = styled(Button)({
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    background: '#237182',
    borderRadius: '8px',
    padding: '10px 16px 10px 16px',
    textTransform: 'none' as 'none',
    cursor: 'pointer',
    marginLeft: '16px',
    "&:hover":{
    background: '#237182',

    },
    '@media screen and (max-width: 1247px)': {
        padding: '5px'
    },
    '@media screen and (max-width: 1170px)': {
        fontSize: '14px',
        padding: '5px'
    },
    '@media screen and (max-width: 1155px)': {
        padding: '4px'
    }
})
const MenuDropdown = styled(Menu)({
  '& .MuiList-padding': {
      paddingTop: '3px',
      paddingBottom: '3px'
  },
  '& .MuiPaper-elevation8': {
      boxShadow: 'unset',
      border: '1px solid #E0EDF0'
  },
  "& .MuiButtonBase-root":{
    borderRadius:"8px",
    padding: "8px 16px",
    "&:hover":{
      background:"#E0EDF0",
      borderRadius:"8px",
      padding: "8px 16px",

    }
  }
})

// Customizable Area End
