// Customizable Area Start
import React, { Suspense } from "react";

import {
    Box,
    BoxProps,
    Button,
    Grid,
    InputBase,
    Paper,
    Typography,
    styled,
    withStyles,
    Dialog,
    DialogActions,
    TextField,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Menu,
    MenuItem,
    Checkbox,
    IconButton,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Dropzone from "react-dropzone";
import { scalingIcon, pdfIcon, pdfFileIcon, userIcon, cancelIcon, pdfFileWarningIcon, warningIcon, reload, noData } from "./assets";
interface CustomButtonBoxProps extends BoxProps {
    toggle: string;
    type: string;
}

import FileDashboardController, {
    Props
} from "./FileDashboardController"

    ;
import { ArrowBackIos, AttachFileOutlined, Check, Clear, CloseOutlined, DoneOutlined, ExpandMore, KeyboardArrowDown, MoreVertOutlined, Search, } from "@material-ui/icons";

import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { FileDownloadOutlined, FileUploadOutlined } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/src/Loader.web";

export default class FileDashboard extends FileDashboardController {

    constructor(props: Props) {
        super(props);
    }

    UploadButton = styled("button")(({ isEnabel }: any) => ({
        borderRadius: "8px",
        border: "1px solid #C5E0E7",
        fontWeight: 600,
        fontSize: "16px",
        height: "56px",
        letterSpacing: 0,
        textTransform: "none",
        padding: "10px 16px",
        boxShadow: "none",
        fontFamily: "Poppins",
        borderWidth: 0,
        cursor: "pointer",
        backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
        color: isEnabel ? "#FFFFFF" : "#64748B",
        transition: "all 0.3s ease",
        "&:hover": {
            backgroundColor: isEnabel ? "#237182" : "#F1F5F9",
        },
    }));
    InfoCard = ({ id, title, fileCount, name }: any) => {

        return (
            <Grid item xs={12} sm={6} lg={3} xl={3} style={{ padding: '10px' }}>
                <Box style={{ backgroundColor: '#3B82F6', borderTop: '8px solid #3B82F6', borderRadius: '12px 12px 11px 12px' }}>
                    <Paper className="third_grid_box" style={{ borderRadius: '11px', marginTop: '-3' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography className="thirdGridHeadingTypo">{title}</Typography>
                            </Box>

                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            <Box>
                                <Box className="thirdGridDataTypoBox">

                                    <img style={{ marginBottom: '6px' }} src={pdfIcon} />&nbsp;


                                    <Typography className="thirdGridNameTypo">Files:</Typography>&nbsp;

                                    <Box>
                                        <Typography className="thirdGridNameTypo">{fileCount}</Typography>
                                    </Box>
                                </Box>
                                <Box className="thirdGridDataTypoBox">
                                    <Box>
                                        <img style={{ marginTop: '0px' }} src={userIcon} />&nbsp;
                                    </Box>
                                    <Box>
                                        <Typography className="thirdGridNameTypo">{name}</Typography>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Paper>
                </Box>
            </Grid>
        );
    };
    getFileErrorMessage = (isFileTooLarge: boolean, errorMessage: string | null, fileSize: string) => {
        if (isFileTooLarge) {
            return "File size must not exceed 10 MB.";
        }

        if (errorMessage) {
            return "File loading error";
        }

        return fileSize;
    }

    onDrop = (acceptedFiles: File[]) => {
        console.log('@@!!!', acceptedFiles[0]);
        const file = acceptedFiles[0];

        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024);

            const transformedFile = {
                name: file.name,
                size: `${fileSizeInMB.toFixed(2)} MB`,
            };
            if (file.type !== "application/pdf") {
                this.setState({
                    errorMessage: "Only PDF files are allowed.",
                    fileMetadata: transformedFile,
                    file: file,
                });
                return;
            }

            if (fileSizeInMB > 10) {
                this.setState({
                    errorMessage: null,
                    fileMetadata: transformedFile,
                    file: file,
                    fileSizeError: true
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    fileUrl: reader.result as string,
                    fileMetadata: transformedFile,
                    file: file,
                    errorMessage: null,
                    fileSizeError: false
                });

            };
            reader.readAsDataURL(file);

        }
    };
    renderFileBlock = (fileMetaData: { name: string; size: string } | null, index: number) => {
        if (!fileMetaData) {
            return null;
        }

        const fileSizeInMB = parseFloat(fileMetaData.size);

        const isFileTooLarge = fileSizeInMB > 10;
        const fileErrorMessage = this.getFileErrorMessage(isFileTooLarge, this.state.errorMessage, fileMetaData.size);

        return (
            <>
                {this.state.errorMessage && (
                    <Box style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginTop: '6px' }}>
                        <img src={warningIcon} alt="" />
                        <Typography
                            style={{
                                color: '#DC2626',
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                textAlign: 'left',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                            }}
                        >
                            {this.state.errorMessage}
                        </Typography>
                    </Box>
                )}

                <Typography className="attachedFileTypo">Attached Files:</Typography>

                {this.state.file && (
                    <Paper
                        key={index}
                        style={{
                            boxShadow: 'rgba(0.1, 0, 0, 0.1) 0px 4px 5px 5px',
                            borderRadius: '8px',
                            padding: '6px 8px',
                            marginTop: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderTop: isFileTooLarge || this.state.errorMessage ? '2px solid #DC2626' : '2px solid #34D399',
                        }}
                    >
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                            <Box>
                                <img src={isFileTooLarge || this.state.errorMessage ? pdfFileWarningIcon : pdfFileIcon} alt="pdfFileIcon" />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className="fileNameTypo">{fileMetaData.name}</Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        lineHeight: '16px',
                                        textAlign: 'left',
                                        textUnderlinePosition: 'from-font',
                                        textDecorationSkipInk: 'none',
                                        color: isFileTooLarge || this.state.errorMessage ? '#DC2626' : '#1E293B',
                                    }}
                                >
                                    {fileErrorMessage}
                                </Typography>
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', gap: 8 }}>
                            {isFileTooLarge && <>
                                <Box onClick={this.handleRemoveUploadedFile}>
                                    <img src={reload} style={{ marginTop: '5px', height: 18, width: 18, cursor: 'pointer' }} />
                                </Box>
                            </>}
                            {this.state.errorMessage == 'Only PDF files are allowed.' && <>
                                <Box onClick={this.handleRemoveUploadedFile}>
                                    <img src={reload} style={{ marginTop: '5px', height: 18, width: 18, cursor: 'pointer' }} />
                                </Box>
                            </>}
                            <Box onClick={this.handleRemoveUploadedFile}>
                                <img src={cancelIcon} alt="cancelIcon" />
                            </Box>
                        </Box>
                    </Paper>
                )}
            </>
        );
    };
    getColor = (type: string) => {
        switch (type) {
            case 'Electrical':
                return '#B45309'
            case 'Plumbing':
                return '#fff';
            case 'Heating':
                return '#EF4444'
        }
    };
    getBackColor = (type: string) => {
        switch (type) {
            case 'Electrical':
                return '#FCD34D'
            case 'Plumbing':
                return '#2563EB'
            case 'Heating':
                return '#FEE2E2'
        }
    }

    render() {
        const selectedText = this.state.selectedItems.has("All") ? "All" : Array.from(this.state.selectedItems).join(", ");
        return (
            <MainWrapper>

                <MyProfileHeader navigation={this.props.navigation} tabName={'Scaling&Drawings'} />
                <CustomToast  />
                <Loader loading={this.state.loader}/>
                <CustomUploadDialog PaperProps={{
                    style: {
                        width: '584px',
                        borderRadius: "16px",
                        padding: "24px 24px",
                        color: "#FFFFFF",
                        boxSizing: "border-box"
                    }
                }} open={this.state.openUploadDialog} onClose={this.handleCloseUploadDialog}>
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "40px",
                            }}
                        >
                            <Typography className="dialogTitleTypo">Upload File</Typography>
                            <Typography
                                data-test-id="handleCloseUploadDialog"
                                onClick={this.handleCloseUploadDialog}
                                style={{
                                    color: "black",
                                    cursor: "pointer",
                                    width: "20px",
                                }}
                            >
                                <Clear />
                            </Typography>
                        </Box>
                        <Box>
                            {(this.state.errorMessage || this.state.fileSizeError || !this.state.file) && <Dropzone
                                onDrop={this.onDrop}
                                multiple={false}
                            >
                                {({ getRootProps, getInputProps }: any) => (
                                    <>
                                        <Box
                                            {...getRootProps()}
                                            style={{
                                                border: "2px dashed #CBD5E1",
                                                borderRadius: "8px",
                                                padding: "40px",
                                                textAlign: "center",
                                                cursor: "pointer",
                                                backgroundColor: "#F8FAFC",
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <Typography
                                                style={{
                                                    color: '#292524',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    lineHeight: '22px',
                                                    textAlign: 'center',
                                                    textUnderlinePosition: 'from-font',
                                                    textDecorationSkipInk: 'none',
                                                }}
                                            >
                                                Drag & Drop Single File Here
                                            </Typography>
                                            <Typography
                                                style={{
                                                    marginTop: '4px',
                                                    color: '#475569',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '12px',
                                                    fontWeight: 400,
                                                    lineHeight: '16px',
                                                    textAlign: 'center',
                                                    textUnderlinePosition: 'from-font',
                                                    textDecorationSkipInk: 'none',
                                                }}
                                            >
                                                Acceptable format: PDF
                                            </Typography>
                                            <Typography
                                                style={{
                                                    color: '#475569',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '12px',
                                                    fontWeight: 400,
                                                    lineHeight: '16px',
                                                    textAlign: 'center',
                                                    textUnderlinePosition: 'from-font',
                                                    textDecorationSkipInk: 'none',
                                                }}
                                            >
                                                (Max size: 10MB)
                                            </Typography>
                                        </Box>

                                    </>
                                )}
                            </Dropzone>}


                            {this.state.file && this.renderFileBlock(this.state.fileMetadata, 0)}
                            <Box>
                                <Typography style={webStyle.fileTypeUpload}>File Type:</Typography>
                                <Box style={{ marginTop: '8px', display: 'flex' }}>
                                    <Button data-test-id="selectedFileType" onClick={() => this.setState({ selectedFileType: 'Electrical' })}
                                        style={{
                                            ...webStyle.fileTypeButton, background: this.state.selectedFileType == 'Electrical' ? '#E0EDF0' : 'none',
                                            border: this.state.selectedFileType == 'Electrical' ? '1px solid #D3E4E9' : 'none'
                                        }}>Electrical</Button>
                                    <Button data-test-id="selectedFileType" onClick={() => this.setState({ selectedFileType: 'Heating' })}
                                        style={{
                                            ...webStyle.fileTypeButton, background: this.state.selectedFileType == 'Heating' ? '#E0EDF0' : 'none',
                                            border: this.state.selectedFileType == 'Heating' ? '1px solid #D3E4E9' : 'none'
                                        }}>Heating</Button>
                                    <Button data-test-id="selectedFileType" onClick={() => this.setState({ selectedFileType: 'Plumbing' })}
                                        style={{
                                            ...webStyle.fileTypeButton, background: this.state.selectedFileType == 'Plumbing' ? '#E0EDF0' : 'none',
                                            border: this.state.selectedFileType == 'Plumbing' ? '1px solid #D3E4E9' : 'none'
                                        }}>Plumbing</Button>
                                </Box>
                            </Box>

                        </Box>

                        <DialogActions
                            style={{
                                marginTop: "38px",
                                gap: "16px",
                                padding: 0,
                            }}
                        >
                            <CancelButton data-test-id="handleCloseUploadDialog" onClick={this.handleCloseUploadDialog}>Cancel</CancelButton>
                            <this.UploadButton disabled={this.state.errorMessage || this.state.fileSizeError || !this.state.file ||this.state.selectedFileType==''} isEnabel={this.state.file && !this.state.errorMessage && !this.state.fileSizeError && this.state.selectedFileType!=''} onClick={this.handleUploadFile}>
                                {this.state.file && !this.state.errorMessage && !this.state.fileSizeError ||this.state.selectedFileType=='' ? 'Upload' : 'Upload'}
                            </this.UploadButton>
                        </DialogActions>
                    </Box>
                </CustomUploadDialog>


                <Box className="headerTitles">
                    <Box className="back">
                        <ArrowBackIos onClick={this.handleBack} style={{ color: '#334155', cursor: 'pointer',height:'14px' }} />
                        <Typography onClick={this.handleBack} className="backText">Back</Typography>
                        <Typography className="projectTitle">Project Name</Typography>
                    </Box>
                    <Button className="uploadButton" data-test-id='uploadFile' onClick={this.uploadFile}>
                        <FileUploadOutlined style={{ color: '#fff' }} />
                        Upload Pdf
                    </Button>
                </Box>
                <Box className="headerTitles" style={{ marginTop: '24px' }}>
                    <SearchContainer>
                        <StyledSearchIcon />
                        <StyledInputBase
                            onChange={this.handleChangeSearch}
                            placeholder="Search here..."
                            inputProps={{ 'aria-label': 'search' }}
                            value={this.state.searchText}
                        />
                    </SearchContainer>
                    <Box style={{ display: 'flex', gap: '5px', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 400, paddingInline: '4px', fontFamily: 'Poppins' }}>File Type:</Typography>
                            <Box data-test-id="toggleDropdown" onClick={this.toggleDropdown} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <Typography style={{ color: "#284F58", fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins" }}>
                                    {selectedText}
                                </Typography>
                                <KeyboardArrowDown style={{ color: "#284F58" }} />
                            </Box>
                        </Box>
                        <Menu
                        data-test-id="onclose"
                            anchorEl={this.state.anchorEl}
                            open={this.state.isDropdownOpen}
                            onClose={() => this.setState({ isDropdownOpen: false })}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            PaperProps={{
                                style: {
                                    marginTop: "46px",
                                    padding: '1px 4px',
                                    borderRadius: '8px',
                                    width: '130px'
                                },
                            }}
                        >
                            {this.state.fileType.map((option: string) => (
                                <MenuItem key={option} value={option} style={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Poppins' }}>
                                    <Checkbox
                                    data-test-id="handleCheckboxChange"
                                        value={option}
                                        checked={this.state.selectedItems.has(option)}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => this.handleCheckboxChange(option)}
                                        style={{
                                            padding: 0,
                                            borderRadius: '6px',
                                            width: '20px',
                                            height: '20px',
                                            pointerEvents: 'auto',
                                            cursor: 'pointer',
                                            marginRight: '8px'
                                        }}
                                        icon={
                                            <span
                                                style={{
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '6px',
                                                    width: '20px',
                                                    height: '18px',
                                                    border: '1px solid #64748B',
                                                    paddingBottom: '1px',
                                                    display: 'inline-block',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        }
                                        checkedIcon={
                                            <span
                                                style={{
                                                    backgroundColor: '#325962',
                                                    color: '#fff',
                                                    borderRadius: '6px',
                                                    width: '20px',
                                                    height: '18px',
                                                    display: 'inline-block',
                                                    border: '1px solid #325962',
                                                    paddingBottom: '1px',
                                                }}
                                            >
                                                <Check style={{ color: 'white', fontSize: 18 }} />
                                            </span>
                                        }
                                    />
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 400, paddingInline: '4px', fontFamily: 'Poppins' }}>Sort By:</Typography>
                        <Box data-test-id="toggleDropdown2" onClick={this.toggleDropdown2} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                            <Typography style={{ color: "#284F58", fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins" }}>
                                {this.state.sortTypeValue}
                            </Typography>
                            <KeyboardArrowDown style={{ color: "#284F58" }} />
                        </Box>
                        <Menu
                        data-test-id="isDropdownOpen2"
                            anchorEl={this.state.anchorEl2}
                            open={this.state.isDropdownOpen2}
                            onClose={() => this.setState({ isDropdownOpen2: false })}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            PaperProps={{
                                style: {
                                    marginTop: "46px",
                                    padding: '1px 4px',
                                    borderRadius: '8px',
                                    width: '140px'
                                },
                            }}
                        >
                            {this.state.sortType.map((option: string) => (
                                <MenuItem key={option} value={option} style={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Poppins' }}>
                                    <Checkbox
                                    data-test-id="handleCheckboxChange2"
                                        value={option}
                                        checked={this.state.sortTypeValue == option}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => this.handleCheckboxChange2(option)}
                                        style={{
                                            padding: 0,
                                            borderRadius: '6px',
                                            width: '20px',
                                            height: '20px',
                                            pointerEvents: 'auto',
                                            cursor: 'pointer',
                                            marginRight: '8px'
                                        }}
                                        icon={
                                            <span
                                                style={{
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '6px',
                                                    width: '20px',
                                                    height: '18px',
                                                    border: '1px solid #64748B',
                                                    paddingBottom: '1px',
                                                    display: 'inline-block',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        }
                                        checkedIcon={
                                            <span
                                                style={{
                                                    backgroundColor: '#325962',
                                                    color: '#fff',
                                                    borderRadius: '6px',
                                                    width: '20px',
                                                    height: '18px',
                                                    display: 'inline-block',
                                                    border: '1px solid #325962',
                                                    paddingBottom: '1px',
                                                }}
                                            >
                                                <Check style={{ color: 'white', fontSize: 18 }} />
                                            </span>
                                        }
                                    />
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                        <Typography className="fileCount">Files: {this.state.tableBody.length}</Typography>
                    </Box>
                </Box>

                <Box style={{ marginTop: '24px', width: '100%' }}>
                    {this.state.tableBody.length==0 &&<>
                        <div style={{textAlign:'center'}}>
                            <img src={noData} style={{width:'457px',height:'350px'}}/>
                            <Typography style={webStyle.noResult}>No Results Found</Typography>
                            {this.state.searchText && <Typography style={webStyle.noSearch}>We couldn't find that you searched for.<br/>Try searching again.</Typography>}
                        </div>
                    </>}
                    {this.state.tableBody.length!=0 &&
                    <TableContainer style={{ width: '100%' }}>
                        <TableHead>
                            <TableRow style={{ background: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                                <TableCell style={webStyle.tableHeading}>File Name</TableCell>
                                <TableCell style={{ ...webStyle.tableHeading, textAlign: 'center' }}>Date Edited</TableCell>
                                <TableCell style={{ ...webStyle.tableHeading, textAlign: 'center' }}>Attached to Proposal</TableCell>
                                <TableCell style={{ ...webStyle.tableHeading, textAlign: 'center' }}>File Type</TableCell>
                                <TableCell style={{ width: '100px' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tableBody.map((item: any) => (
                                <TableRow style={{ background: '#fff' }} >
                                    <TableCell data-test-id="navigateMarkupTool" onClick={this.navigateMarkupTool} style={webStyle.tableBody}>{item?.attributes?.project_document?.file_name}</TableCell>
                                    <TableCell onClick={this.navigateMarkupTool} style={{ ...webStyle.tableBody, textAlign: 'center' }}>{item?.attributes?.project_document?.created_at}</TableCell>
                                    <TableCell onClick={this.navigateMarkupTool} style={{ textAlign: 'center' }}>{item?.attributes?.attached_to_perposal ? <DoneOutlined style={{ color: '#237182' }} /> : <CloseOutlined style={{ color: '#94A3B8' }} />}</TableCell>
                                    <TableCell onClick={this.navigateMarkupTool} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center',paddingTop:'16px',paddingBottom:'16px' }}>
                                        <Typography
                                            style={{ ...webStyle.tableBody, width: 'fit-content', textAlign: 'center', color: this.getColor(item?.attributes?.file_type), background: this.getBackColor(item?.attributes?.file_type), borderRadius: '40px', padding: '3px 8px', fontWeight: 700, fontSize: '12px', textTransform: 'uppercase' }}
                                        >{item?.attributes?.file_type}</Typography></TableCell>
                                    <TableCell style={{ textAlign: 'end' }}>  <IconButton data-test-id="handleMenuOpen" onClick={(event) => this.handleMenuOpen(event, item)}>
                                        <MoreVertOutlined style={{ color: '#475569' }} />
                                    </IconButton></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </TableContainer>
                    }
                    <Menu
                anchorEl={this.state.anchorElpop}
                open={Boolean(this.state.anchorElpop)}
                onClose={this.handleMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        marginTop: "46px",
                        borderRadius: '8px',
                        border:'1px solid #CBD5E1',
                        width: !this.state.selectedFile.proposal?'189px':'130px'
                    },
                }}
            >
                {this.state.selectedFile && !this.state.selectedFile.proposal && (
                    <MenuItem data-test-id="handleAttachToProposal" onClick={this.handleAttachToProposal}><AttachFileOutlined style={{color:'#0F172A',marginRight:'5px',height:'17px'}}/><Typography style={webStyle.menuitem}>Attach to Proposal</Typography></MenuItem>
                )}
                <MenuItem data-test-id="handleDownload" onClick={this.handleDownload}><FileDownloadOutlined style={{color:'#0F172A',marginRight:'5px',height:'17px'}}/><Typography style={webStyle.menuitem}>Download</Typography></MenuItem>
            </Menu>
                </Box>
            </MainWrapper>

        );
    }
}
const webStyle = {
    tableHeading: {
        color: '#475569',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 600,
        paddingTop:'16px',
        paddingBottom:'16px'
    },
    tableBody: {
        color: '#1E293B',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    },
    fileType: {
        color: '#284F58',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    fileTypeUpload: {
        color: '#334155',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        marginTop: '16px'
    },
    fileTypeButton: {
        border: 'none',
        padding: '10px 12px',
        borderRadius: "50px",
        width: '100%',
        textTransform: 'none' as 'none',
        fontSize: '16px',
        fontWeight: 600,
        color: '#325962',
        fontFamily: 'Poppins' as 'Poppins'
    },
    menuitem:{
        color:'#0F172A',
        fontSize:'14px',
        fontFamily:'Poppins',
        fontWeight:400
    },
    noResult:{
        color:'#1E293B',
        fontSize:'16px',
        fontWeight:600,
        fontFamily:'Poppins',
        marginTop:'16px'
    },
    noSearch:{
        color:'#475569',
        fontFamily:'Poppins',
        fontSize:'14px',
        fontWeight:400,
        marginTop:'8px'
    }
}
const CustomToast = styled(ToastContainer)({
    width: "unset",
    marginTop: "80px",
    "& .Toastify__toast": {
      boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
      padding: "0.5rem 1rem",
      borderRadius: "8px",
    },
    "& .Toastify__toast-body": {
      fontFamily: "Poppins",
      color: "#0F172A",
      padding: 0,
    },
  });
const CustomDropDown = styled(TextField)({
    "& .MuiInput-underline:before": {
        borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
        borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "#fff",
        color: "#237182",
    },
    "& .MuiSelect-select": {
        paddingBlock: "10px",
        color: "#237182",
        fontSize: "16px",
        fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
        color: "#237182",
    },
});
const MainWrapper = styled(Box)(({ theme }) => ({
    height: '100vh',
    background: '#F0F5F7',
    padding: '24px 40px',
    '& .headerTitles': {
        display: 'flex',
        justifyContent: 'space-between'
    },
    '& .back': {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .backText': {
        color: '#334155',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        marginLeft: '6px',
        marginRight: '16px',
        cursor: 'pointer'
    },
    '& .projectTitle': {
        color: '#1E293B',
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins'
    },
    '& .uploadButton': {
        background: '#237182',
        borderRadius: '8px',
        padding: '10px 16px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        textTransform: 'none',
        cursor: 'pointer'
    },
    '& .fileCount': {
        color: '#334155',
        fontSize: '14px',
        fontWeigth: 400,
        fontFamily: 'Poppins'
    },
    '& .MuiTableCell-head': {
        width:'315px',
        paddingTop:'16px',
        paddingBottom:'16px',
        '@media (max-width:1600px)': {
            width: '315px'
        },
        '@media (max-width:1500px)': {
            width: '300px'
        },
        '@media (max-width:1440px)': {
            width: '276px'
        },
    },
    "& .main_box": {
        marginTop: '24px'
    },
    "& .main_paper": {
        marginTop: '32px',
        marginBottom: '32px',
        borderRadius: '12px'
    },
    "& .main_paper1": {
        marginTop: '16px',
        marginBottom: '32px',
        borderRadius: '12px'
    },
    "& .main_paper2": {
        marginTop: '32px',
        marginBottom: '24px',
        borderRadius: '12px'
    },
    "& .first_grid_box": {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '0px 24px'
    },
    "& .second_grid_box": {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '24px 24px'
    },
    "& .third_grid_box": {
        padding: '16px 24px'

    },
    "& .thirdGridHeadingTypo": {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        textOverflow: 'ellipsis',
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .thirdGridDataTypoBox": {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '4px',
        alignItems: 'center'
    },
    "& .thirdGridDataTypoBox2": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .thirdGridDataTypo": {
        color: '#64748B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none'
    },
    "& .thirdGridNameTypo": {
        color: '#64748B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .mainBoxFirst": {
        display: 'flex',
        justifyContent: 'space-between'

    },
    "& .OptionBox": {
        display: "flex",
        justifyContent: "start",
    },
    "& .singleOptionBox": {
        display: "flex",
        backgroundColor: "#F0F5F7",
        borderRadius: "50px",
        padding: "4px",
        height: '36px',
        gap: '8px'
    },
    "& .search": {
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    '& .MuiTableCell-root':{
        padding:'0px 16px'
    }
}));
const styles = {
    root: {
        borderRadius: '50px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        padding: '6px 12px',
        cursor: 'pointer',
        fontSize: '14px',
        backgroundColor: (props: CustomButtonBoxProps) =>
            props.toggle === props.type ? '#E0EDF0' : '#F0F5F7',
        border: (props: CustomButtonBoxProps) =>
            props.toggle === props.type ? '1px solid #D3E4E9' : 'none',
        color: (props: CustomButtonBoxProps) =>
            props.toggle === props.type ? '#325962' : '#64748B',
        transition: 'all 0.3s ease',
    },
};

const CustmoButtonBox = withStyles(styles)((props: CustomButtonBoxProps) => {
    const { toggle, type, ...otherProps } = props;
    return <Box {...otherProps} />;
});

const SearchContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    padding: theme.spacing(0.5, 1),
    maxWidth: '400px',
    // boxShadow: `0px 2px 4px ${theme.palette.divider}`,
}));

const StyledSearchIcon = styled(Search)(({ theme }) => ({
    marginRight: 0,
    color: theme.palette.text.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.primary,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
        transition: theme.transitions.create(['width']),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '200px',
            color: '#94A3B8',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            '&:focus': {
                width: '250px',
                color: '#334155',
            },
        },
    },
}));
const ScalingButton = styled(Button)({
    backgroundColor: "#E0EDF0",
    borderRadius: "8px",
    border: "1px solid #C5E0E7",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "16px",
    height: "44px",
    letterSpacing: 0,
    textTransform: "none",
    padding: "10px 16px 10px 16px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth: 0,
    '&:hover': {
        backgroundColor: '#E0EDF0',
    },


});
const CustomUploadDialog = styled(Dialog)({
    "& .dialogTitleTypo": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .fileNameTypo": {
        color: '#0F172A',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .fileSizeTypo": {
        color: '#64748B',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    "& .attachedFileTypo": {
        marginTop: '16px',
        color: '#334155',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    }


});
const CancelButton = styled(Button)({
    backgroundColor: "#E0EDF0",
    borderRadius: "8px",
    border: "1px solid #C5E0E7",
    color: "#325962",
    fontWeight: 600,
    fontSize: "16px",
    height: "56px",
    letterSpacing: 0,
    textTransform: "none",
    padding: "10px 16px 10px 16px",
    boxShadow: "none",
    fontFamily: "Poppins",
    borderWidth: 0,
    '&:hover': {
        backgroundColor: '#E0EDF0',
    },
});
// Customizable Area End
